import {
  SB,
  SecB,
  NeutralB,
} from 'CORE__UI/buttons/ModalButton/core__modalButtonStyles';
import styled, { css } from 'styled-components';
import { BRAND, GREYS } from '../globals/colours';

const SB_GDWN = styled(SB)`
  ${({ isBlocked }) =>
    isBlocked &&
    css`
      color: ${GREYS.silver};
      background-color: ${BRAND.primary} !important;
      border: none;
      &:hover,
      &:active,
      &:focus {
        color: ${GREYS.silver} !important;
      }
    `}
  ${({ showJNModal }) =>
    showJNModal &&
    css`
      color: ${GREYS.white};
      border: 1px solid ${BRAND.primary};
    `}

      ${({ bbFlag }) =>
    bbFlag
      ? css`
          background-color: ${GREYS.doveD};
        `
      : css`
          background-color: ${BRAND.secondary};
        `};

  &:hover,
  &:active,
  &:focus {
    color: ${GREYS.black};
    ${({ bbFlag }) =>
      bbFlag
        ? css`
            background-color: ${GREYS.doveD};
          `
        : css`
            background-color: ${BRAND.secondary};
          `};
  }
`;
const SecB_GDWN = styled(SecB)`
  color: ${GREYS.white};
  background-color: transparent;
  &:hover,
  &:active,
  &:focus {
    color: ${GREYS.white};
    background-color: transparent;
  }
`;
export { SB_GDWN as SB, SecB_GDWN as SecB, NeutralB };
