import styled, { css } from 'styled-components';
import { GREYS, FONT, BRAND, SHADOW } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

export const THW = styled.div`
  font-weight: 800;
  display: flex;
`;
export const LB = styled.span`
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 32px;
  border-radius: 4px;
  color: white;
  margin-right: 8px;
  white-space: nowrap;
  &:hover {
    border: 2px solid ${GREYS.white};
  }
  @media (hover: none) {
    &:hover {
      border: none;
    }
  }
  @media (max-width: ${BREAKPOINT_S}) {
    width: auto;
    padding: 0 1em;
  }
  margin-right: 8px;
  //margin for icon
  span {
    margin-left: 8px;
  }
`;

export const CT = styled.span`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 32px;
  color: white;
  white-space: nowrap;
  //margin for icon
  span {
    margin-right: 8px;
  }
`;

export const RB = styled.span`
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 32px;
  border-radius: 4px;
  background-color: ${FONT.secondary};
  color: ${GREYS.black};
  &:hover {
    background-color: ${BRAND.secondary};
  }

  @media (max-width: ${BREAKPOINT_S}) {
    width: auto;
    background-color: transparent;
    color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
    font-weight: normal;
    &:hover {
      background-color: ${BRAND.secondaryD};
    }

    ${({ hide }) =>
      hide &&
      css`
        display: none;
      `}
  }
`;

export const RBW = styled.span`
  @media (max-width: ${BREAKPOINT_S}) {
    display: none;
  }
`;
export const RBM = styled.span`
  display: none;
  @media (max-width: ${BREAKPOINT_S}) {
    display: block;
    padding: 0 1em;
  }
`;

export const EC = styled.div`
  display: flex;
  align-items: center;
`;

export const E = styled.span`
  background-color: ${BRAND.primaryL};
  padding: 12px;
  font-size: 14px;
  color: ${FONT.light};
  cursor: pointer;
`;

export const B = styled.div`
  background-color: ${BRAND.primaryL};
  padding: 12px;
  border-radius: 0 4px 4px 0;
  background-color: ${FONT.light};
  color: ${FONT.dark};
  font-weight: normal;
  text-align: right;
  width: auto;
  min-width: 100px;
  padding-left: 12px;
  @media (max-width: 1280px) {
    min-width: unset;
  }
`;

export const EX = styled.div`
  padding: 12px;
  width: 120px;
  color: ${BRAND.primary};
  border-radius: 4px;
  background-color: ${FONT.light};
  text-align: center;
  cursor: pointer;
`;

export const BS = styled.div`
  margin-right: 20px;
  display: flex;
`;

export const R = styled.div`
  cursor: pointer;
  color: ${FONT.light};
  font-size: 14px;
  position: relative;
`;
export const RS = styled.a`
  margin-right: 40px;
  @media (max-width: 1280px) {
    margin-right: 20px;
  }
  font-weight: normal;
`;

export const MB = styled.div`
  color: ${({ active }) => (active ? FONT.secondary : FONT.light)};
  margin-right: 40px;
`;

export const BC = styled.span`
  top: -4px;
  right: 24px;
  min-height: 12px;
  min-width: 12px;
  background-color: ${FONT.secondary};
  border-radius: 50%;
  font-size: 10px;
  color: ${FONT.dark};
  padding-left: 4px;
  position: absolute;
`;
export const TCTP = styled.button`
  background-color: inherit;
  color: ${GREYS.white};
  padding-inline: 36px;
  padding-block: 8px;
  border-radius: 4px;
  border: 1px solid ${GREYS.white};
`;

export const SI = styled.img`
  height: 12px;
  top: -5px;
  position: absolute;
  left: 13px;
`;

export const UAI = styled.span`
  position: relative;
`;
export const DP = styled.div`
  pointer-events: all;
`;

export const CP = styled.div`
  position: fixed;
  width: 344px;
  box-shadow: ${SHADOW};
  border-radius: 4px;
  top: 35%;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: ${GREYS.silverD};
  border: 1px solid ${GREYS.silver};
  color: ${GREYS.white};
`;

export const TT = styled.div`
  text-align: center;
  font-size: 14px;
`;

export const L = styled.div`
  margin: 24px;
  padding: 8px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: ${GREYS.white};
  border: 1px solid ${GREYS.silver};
  border-radius: 4px;
  cursor: pointer;
`;

export const C = styled.div`
  text-align: right;
  font-size: 18px;
  cursor: pointer;
`;

export const DC = styled.div`
  justify-content: space-between;
  display: flex;
  padding: 24px;
`;

export const IB = styled.div`
  span {
    margin-right: 2px;
    margin-left: 5px;
  }
`;
export const BW = styled.div`
  pointer-events: all;
`;
export const MWN = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const MWD = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
export const IMAGE = styled.img`
  margin-left: 8px;
`;
export const BETCOUNTER = styled.span`
  bottom: 2px;
  position: relative;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 100%;
  margin-left: 16px !important;
  height: 20px;
  width: 20px;
  display: flex;
  color: ${GREYS.black};
  background: ${BRAND.tertiaryL};
`;

export const ACCOUNTBALANCECOUNTER = styled.span`
  bottom: 2px;
  position: relative;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 100%;
  margin-left: 16px;
  height: 20px;
  width: 20px;
  display: flex;
`;
export const UBW = styled.div`
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 12px;
    font-weight: normal;
  }
`;
export const COL = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
`;
export const ROW = styled.div`
  display: flex;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  gap: 5px;
  align-items: center;
`;
