import {
  MD,
  ST,
  T,
  I,
  B,
  MStyles,
} from 'CORE__UI/globals/SessionExpiry/core__session-expiryStyles';
import styled from 'styled-components';
import { GREYS } from 'UI/globals/colours';
const JN_I = styled(I)`
  color: ${GREYS.white};
`;

const JN_T = styled(T)`
  color: ${GREYS.white};
`;
export { MD, ST, JN_T as T, JN_I as I, B, MStyles };
