import styled, { css } from 'styled-components';
import { GREYS, FONT, FUNCTIONAL, BRAND } from 'UI/globals/colours';
import ModalButton from 'UI/buttons/ModalButton';
import { getModalWidth } from '../../../components/LoginApp/core__login-store';
import { BREAKPOINT_M } from 'UI/globals/breakpoints';

export const H = styled.h5`
  font-size: 18px;
  color: ${FONT.primary};
  margin-bottom: 8px;
  text-align: center;
  font-weight: 800;
`;

export const LoginBox = styled.div`
  width: 100%;
  padding: 16px;
`;

export const D = styled.div`
  height: 1px;
  background-color: ${BRAND.tertiary};
  text-align: center;
  margin: 34px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const S = styled.span`
  position: relative;
  padding: 0 8px;
  top: -2px;
  color: ${GREYS.silver};
  background-color: ${props =>
    props.theme.dark ? GREYS.silverD : GREYS.white};
`;

export const C = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  color: ${GREYS.silver};
  a {
    margin-left: 8px;
    transition: all 250ms ease;
    color: ${props => (props.theme.dark ? GREYS.white : FONT.dark)};
    text-decoration: underline;
    &:hover {
      color: ${FONT.secondary};
    }
  }
`;

export const E = styled.div`
  font-size: 12px;
  margin-top: 4px;
  color: ${FUNCTIONAL.negative};
  text-align: center;
  a {
    display: initial;
  }
`;

export const MB = styled(ModalButton)`
  margin-bottom: 8px;
`;
export const MStyles = {
  modal: {
    padding: '24px',
    width: getModalWidth(BREAKPOINT_M),
    borderRadius: '4px',
    margin: 0,
  },
};
export const ModalButtonClose = styled(ModalButton)`
  background-color: inherit;
  color: ${props => (props.theme.dark ? GREYS.white : GREYS.black)};
  border-color: ${props => (props.theme.dark ? GREYS.white : GREYS.silver)};
  :hover {
    color: ${GREYS.black};
  }
`;
export const EYE = styled.div`
  position: absolute;
  color: ${GREYS.white};
  z-index: 1;
  right: 28px;
  top: 173px;
  cursor: pointer;
  ${({ reg }) =>
    reg &&
    css`
      top: 32px;
      right: 12px;
    `}
`;
export const L = styled.div``;
export const J = styled.div``;
export const Text = styled.p``;
export const Link = styled.a``;

export const CB = styled.div`
  color: ${GREYS.white};
  margin-bottom: 16px;
  input {
    position: relative;
    cursor: pointer;
  }
  input:before {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    border: 1px solid ${BRAND.favourite};
    border-radius: 3px;
    background-color: ${GREYS.black};
  }
  input:checked:after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: solid ${BRAND.favourite};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
  }
  label {
    margin-left: 8px;
  }
`;

export const Image = styled.img`
  width: 100%;
`;
export const CI = styled.span`
  height: 30px;
  width: 30px;
  background-color: ${FUNCTIONAL.positive};
  border-radius: 50%;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;
export const OH = styled.h5`
  font-size: 16px;
  color: ${FUNCTIONAL.positive};
  margin-top: 16px;
  text-align: center;
  font-weight: 800;
`;

export const PRST = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 8px;
`;

export const B = styled.div`
  position: relative;
  margin-top: 16px;
`;

export const PRSP = styled.div`
  text-align: center;
  font-size: 16px;
  margin-bottom: 24px;
`;
