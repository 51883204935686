import { isAndroidApp } from 'Services/globalfunctions/core__global-functions';
import project from '../../project';

// https://github.com/adjust/web_sdk

const { adjustConfig = {} } = project;
const { eventTokens, appToken } = adjustConfig;

// https://help.adjust.com/en/article/get-started-web-sdk
const loadAdjustWebSdk = ({ environment = 'sandbox' }) => {
  // if no adjust appToken in project.js, do nothing
  if (!appToken) return;

  // only continue on android wrapper app
  if (!isAndroidApp()) return;

  // install Adjust web sdk
  !(function (t, e, a, r, n, s, o, d, l, i, u) {
    (t.Adjust = t.Adjust || {}), (t.Adjust_q = t.Adjust_q || []);
    for (var c = 0; c < d.length; c++) l(t.Adjust, t.Adjust_q, d[c]);
    (i = e.createElement(a)),
      (u = e.getElementsByTagName(a)[0]),
      (i.async = !0),
      (i.src = 'https://cdn.adjust.com/adjust-latest.min.js'),
      (i.crossOrigin = 'anonymous'),
      (i.integrity = s),
      (i.onload = function () {
        for (var e = 0; e < t.Adjust_q.length; e++)
          t.Adjust[t.Adjust_q[e][0]].apply(t.Adjust, t.Adjust_q[e][1]);
        t.Adjust_q = [];
      }),
      u.parentNode.insertBefore(i, u);
  })(
    window,
    document,
    'script',
    0,
    0,
    'sha384-BqbTn9xyk5DPznti1ZP8ksxKiOFhKufLBFWm5eNMCnZABFSG1eqQfcu5dsiZJHu5',
    0,
    [
      'initSdk',
      'getAttribution',
      'getWebUUID',
      'setReferrer',
      'trackEvent',
      'addGlobalCallbackParameters',
      'addGlobalPartnerParameters',
      'removeGlobalCallbackParameter',
      'removeGlobalPartnerParameter',
      'clearGlobalCallbackParameters',
      'clearGlobalPartnerParameters',
      'switchToOfflineMode',
      'switchBackToOnlineMode',
      'stop',
      'restart',
      'gdprForgetMe',
      'disableThirdPartySharing',
      'initSmartBanner',
      'showSmartBanner',
      'hideSmartBanner',
    ],
    function (t, e, a) {
      t[a] = function () {
        e.push([a, arguments]);
      };
    }
  );
  // https://help.adjust.com/en/article/configuration-methods-web-sdk#initoptions
  // init Adjust web sdk
  window.Adjust.initSdk({ appToken, environment });
};

// https://help.adjust.com/en/article/record-events-web-sdk
// emit tracking event
const adjustTrackEvent = (eventToken, other) => {
  const adid = parent.document.querySelector('meta[name="android-id"]')
    ?.content;
  other.push({ key: 'user_id', value: adid });
  const partnerParams = other;
  window.Adjust?.trackEvent?.({ partnerParams, eventToken });
};

export default adjustTrackEvent;

export { loadAdjustWebSdk, eventTokens };
