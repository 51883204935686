import { getCookie } from 'Services/cookie/core__cookies';
import gtmEventNames from './core__gtm-event-names';
import project from '../../project';

const { customGtmEventNames } = project;
const eventNames = customGtmEventNames || gtmEventNames;

const getChannel = () => {
  const { userAgent, platform } = window.navigator;

  const isAndroidWrapperApp = userAgent.startsWith('app=fsb-');
  const isAndroid = userAgent.includes('Android');
  const isDesktop =
    userAgent.includes('Windows') ||
    platform.includes('Mac') ||
    userAgent.includes('Linux');
  const isiOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;

  if (isAndroidWrapperApp) return 'Android';
  if (isAndroid) return 'Web-Android';
  if (isiOS) return 'Web-IOS';
  if (isDesktop) return 'Desktop';

  return 'Web-others';
};

const addCustomGTMEventToDataLayer = ({ event, data }) => {
  // if gtm script has not init'd the dataLayer, we create it
  if (!window.dataLayer) window.dataLayer = [];

  if (!event) return;

  window.dataLayer.push({
    event,
    data: {
      channel: getChannel(),
      user_id: getCookie('custId'), // always send the customer id or false
      ...data,
    },
  });
};

export default addCustomGTMEventToDataLayer;

export { eventNames as gtmEventNames };
