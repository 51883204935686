import styled from 'styled-components';
import { GREYS, BRAND } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';
export const BH = styled.div`
  position: relative;
`;
export const BN = styled.div`
  font-size: 18px;
  color: ${GREYS.white};
  display: flex;
  justify-content: center;
  font-weight: 700;
  position: relative;
`;
export const X = styled.div`
  position: absolute;
  top: 0;
  right: 8px;
  font-size: 20px;
  cursor: pointer;
  color: ${GREYS.white};
`;
export const I = styled.div`
  padding: ${({ removePadding }) => (removePadding ? `0 8px` : `24px`)};
  color: ${GREYS.white};
  text-align: center;
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 12px;
    padding: ${({ removePadding }) => (removePadding ? `0 8px` : `12px`)};
  }
`;
export const B = styled.div`
  margin: 0 24px 8px 24px;
  cursor: pointer;
  background-color: ${BRAND.favourite};
  font-weight: 700;
  border-radius: 4px;
  padding: 12px;
  text-align: center;
`;
export const LL = styled.div`
  text-align: center;
  color: ${GREYS.white};
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  a {
    text-decoration: underline;
    color: ${BRAND.secondary};
    cursor: pointer;
    line-height: 20px;
    margin-left: 4px;
  }
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 12px;
  }
`;
