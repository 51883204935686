//function to check valid email
export const emailVarify = string => {
  // eslint-disable-next-line no-useless-escape
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (regex.test(string.trim())) {
    return true;
  }
  return false;
};
//function to checkEmptyString
export const checkEmptyString = (string, allowBlank) => {
  if (!allowBlank && string.trim() === '') {
    return true;
  }

  if (string.trim() === '') {
    return true;
  }

  return false;
};

/**
 * @TODO Check if we can delete this method, since we are using
 * checkValidFirstAndLastName right now as main validation.
 */
export const checkValidName = (string, allowBlank, allowSpaceInLastname) => {
  const regex = allowSpaceInLastname
    ? /^[a-zA-Z]([\w '-]*[a-zA-Z])?$/g
    : /^[a-zA-Z]*$/g;
  const allowSpecialChars = /^\b(([A-ZÀ-ÿ]|[a-zà-ÿ])[-,a-z. ']+[ ]*)+$/g;

  if (allowBlank && allowSpecialChars.test(string)) {
    return true;
  }

  if (regex.test(string)) {
    return true;
  }
  return false;
};
//function to checkValidNumber
export const checkValidNumber = (number, min, max) => {
  if (/^[0-9]/.test(number) && number.length >= min && number.length <= max) {
    return true;
  }
  return false;
};
//function to checkValid first and last Name
export const checkValidFirstAndLastName = string => {
  const allowSpecialChars = /^\b((([a-zA-Z]|[à-ü]|[À-Ü]|[ZÀ-ÖØ-öø-ÿ])+[ --]*)+['-´]*)+$/g;
  return !allowSpecialChars.test(string);
};
//function to checkValidUsername
export const checkValidUsername = name => {
  const regex = /^[a-zA-Z0-9]{2,20}$/;
  if (regex.test(name)) {
    return true;
  }
  return false;
};
//function to checkValidPassword
export const checkValidPassword = password => {
  // eslint-disable-next-line no-useless-escape
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d][\w~$@%!"#%^&*£+<>(/\\)!?\\-]{8,}$/;
  if (regex.test(password)) {
    return true;
  }
  return false;
};

//function to check CustomPasswordValidation through regex
export const checkCustomPasswordValidation = (regexPassword, password) => {
  // eslint-disable-next-line no-useless-escape
  const regex = new RegExp(regexPassword);
  if (regex && regex.test(password)) {
    return true;
  }
  return false;
};

//function to checkValidPassword
export const checkValidCustomPassword = password => {
  // eslint-disable-next-line no-useless-escape
  const mainRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,14}$/;
  if (mainRegex.test(password)) {
    return true;
  }
  return false;
};
export const checkValidCustomPasswordWithSpecialCharacters = (
  password,
  maxPasswordLength
) => {
  const pattern = maxPasswordLength
    ? /^(?=.*[A-Za-z])(?=.*[0-9]).{6,20}$/gm
    : /^(?=.*[A-Z])(?=.*[\W|_])(?=.*[0-9])(?=.*[a-z])(?=.*).{8,14}$/gm;
  if (pattern.test(password)) {
    return true;
  }
  return false;
};

//function to checkValidPassword with min and max length
export const checkValidPasswordWithLimit = password => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d][\w~$@%!#%^&*£+<>(/\\)!?\\-]{8,14}$/;
  if (regex.test(password)) {
    return true;
  }
  return false;
};

//function to checkValidPassword with atleast one upper, one lower, min 8, max 20and optional special characters
export const checkValidPasswordUpLowMinMax = password => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d][\w~$@%!"#%^&*£+<>(/\\)!?\\-]{7,19}$/;
  if (regex.test(password)) {
    return true;
  }
  return false;
};

/**
 * @param {string} text text value from schema
 * @param {string} regexOrKey Regex pattern to use
 * @param {string} min ammount of characters to be used. Default 8
 * @param {string} max ammount of characters to be used. Default 14
 * @returns boolean
 */
export const validationFromSchema = (
  text,
  regexOrKey,
  min = 8,
  max = 14,
  minPasswordLength
) => {
  const patterns = {
    primary: minPasswordLength
      ? new RegExp(`^(?=.*[A-Za-z])(?=.*[0-9]).{${minPasswordLength},${max}}$`)
      : new RegExp(
          `^(?=.*[A-Z])(?=.*[\\W|_])(?=.*[0-9])(?=.*[a-z]).{${min},${max}}$`,
          'gm'
        ),
    noSpecialChars: new RegExp(`^[a-zA-Z0-9]{${min},${max}}$`),
  };
  if (!text) return true; // if no input

  if (typeof regexOrKey === 'string') {
    return patterns[regexOrKey].test(text);
  }
  return regexOrKey.test(text);
};

//function to checkValidCoupon
export const checkValidCoupon = coupon => {
  const regex = /^[a-zA-Z0-9]{0,30}$/;
  if (regex.test(coupon)) {
    return true;
  }
  return false;
};

//function to checkValidProposingMember
export const checkValidProposingMember = proposingmember => {
  const regex = /^[a-zA-Z0-9]{0,20}$/;
  if (regex.test(proposingmember)) {
    return true;
  }
  return false;
};

//function to checkValidMobileNumber
export const checkValidMobileNumber = mobileNumber => {
  const mobileNumberFormats = [
    /^[0-9]{11,16}$/,
    /^([+]1)[0-9]{10,16}$/,
    /^([+]1)[-](\d{3})[-](\d{3})[-](\d{4,10})$/,
    /^([+]1)[ ][(](\d{3})[)][ ](\d{3})[-](\d{4,10})$/,
  ];
  let check = false;

  mobileNumberFormats.forEach(regex => {
    check = check || regex.test(mobileNumber);
  });

  return check;
};

//function to checkValidCoupon
export const canadianFormRegex = postalCode => {
  const regex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
  if (regex.test(postalCode)) {
    return true;
  }

  return false;
};

//function to check idNumber
export const idVerify = id => {
  // eslint-disable-next-line no-useless-escape
  const regex = /^[0-9]{13}$/;
  if (regex.test(id.trim())) {
    return true;
  }
  return false;
};

export const passportVerification = id => {
  // eslint-disable-next-line no-useless-escape
  const regex = /^[a-zA-Z0-9]{2,9}$/;
  if (regex.test(id.trim())) {
    return true;
  }
  return false;
};
