import React from 'react';
import ReactDOM from 'react-dom';

import ScDecoder from 'Services/json/core__decoder';
import createStore, {
  loginData,
  openLogout,
  showBalance,
  showMyBet,
  showMyBetCount,
  getUserDetails,
  updateAccountBalance,
  setCustomerData,
} from './core__authorization-header.store';
import { Provider as ReduxProvider } from 'react-redux';

import { connect } from 'react-redux';
import { AuthorizationHeaderReact } from './component';
import { TranslationsProvider } from 'Services/translations/core__translations';
import { translationStrings } from './core__authorization-header-translations';

export const Translations = new TranslationsProvider('AuthorizationApp');
class AuthorizationHeaderApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) {
      Translations.get = properties.Translations.get.bind(
        properties.Translations
      );
      Translations.getAll = properties.Translations.getAll.bind(
        properties.Translations
      );
      return;
    }
    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }

  start(data, reduxSelector) {
    const ReactInstance = this.getReactApp();
    Translations.setAll(data);
    const jsx = (
      <ReduxProvider store={createStore(data || {})}>
        <ReactInstance />
      </ReduxProvider>
    );

    ReactDOM[data ? 'hydrate' : 'render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }

  getReactApp() {
    const mapStateToProps = state => {
      return {
        appConfig: this.appConfig,
        theme: this.appConfig?.theme,
        logoutPopup: state.data.logoutPopup,
        additionalLoginInfo: this.appConfig?.additionallogininfo,
        showLoggedInTimer: this.appConfig?.showtimer, //to show user logged in timer clock in header
        starIconIndicator: this.appConfig?.accountdetailsiconurl, // to show star icon indicating if user has any one among sportsbook bonus,free bets,casino bonus, free spins
      };
    };

    const mapDispatchToProps = {
      url: this.url,
      loginData: loginData,
      openLogout: openLogout,
      showBalance: showBalance,
      showMyBet: showMyBet,
      showMyBetCount: showMyBetCount,
      getUserDetails: getUserDetails,
      updateAccountBalance: updateAccountBalance,
      setCustomerData: setCustomerData,
    };

    AuthorizationHeaderReact.serverFetch = {
      createStore: createStore,
      loginData: loginData,
      fetchData: () => dispatch => {
        return new Promise(resolve => {
          dispatch({ type: 'DO_NOTHING' });
          resolve();
        });
      },
      strToTranslate: translationStrings,
    };

    return connect(
      mapStateToProps,
      mapDispatchToProps
    )(AuthorizationHeaderReact);
  }
}

export default AuthorizationHeaderApp;
