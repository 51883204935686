import React, { useEffect, useState } from 'react';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { AuthHeader } from './components/AuthHeader';
import PropTypes from 'prop-types';
import { getCookie, createCookie } from 'Services/cookie/core__cookies';
import { decode } from '@msgpack/msgpack';
import { FSBTheme } from 'Services/core__fsb-theme';
import SessionExpiry from 'UI/globals/SessionExpiry';
import { customerData } from '../core__response.stub';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import { SuccessLoginPopup } from './components/SuccessLoginPopup/core__success-login-popup';
import { CC } from 'UI/apps/AccountApp/AccountHeader';
import PepPopup from './components/PepPopup/core__pep-popup';
import { removeCookie } from 'Services/cookie/core__cookies';
import TRACKING_CONSTANTS from 'Services/constants/core__tracking';
import addCustomGTMEventToDataLayer, {
  gtmEventNames,
} from 'Services/gtm/core__addCustomGTMEventToDataLayer';
import { getCustomerCard } from '../core__authorization-header.store.js';
import { useDispatch } from 'react-redux';

/**
 * this component contains the authorization header including the login and signup
 * @param {*} loginData function for setting the login user data
 *
 **/
export const AuthorizationHeaderReact = props => {
  const dispatch = useDispatch();
  const [showHeaders, setShowHeaders] = useState(false);
  const [loginSuccessModal, setLoginSuccessModal] = useState(false);
  const olympic = props.appConfig.responsiblegambling;
  const hideHeaderButtons = props.appConfig.hideheaderbuttons;
  const [pepModal, setPepModal] = useState(
    getCookie('pepStatePopUp') === 'true'
  );
  const useLoginRegisterPopup = props.appConfig.useloginregisterpopup;
  const additionalInfoLimitsHtml = props.appConfig.additionalinfolimitshtml;
  const [successType, setSuccessType] = useState('login');
  const theme = props.theme;
  const sessionToken = getCookie('AppSession');

  const updateStyle = () => {
    const myBet = document.getElementById('my-bet');
    if (myBet && window.location.pathname !== '/my-account/') {
      myBet.style.display = 'block';
    }
  };
  const updateSession = wsData => {
    updateStyle();

    // Function for sending the updated session data of customer
    props.getUserDetails(wsData.accessToken, props.showLoggedInTimer, wsData);
    setShowHeaders(true);
  };

  useEffect(() => {
    const sessionCreatedPubSub = PubSub.listen(
      PubsubEvents.SESSION_CREATED,
      () => {
        if (
          useLoginRegisterPopup &&
          window.location.href.includes('register')
        ) {
          setSuccessType('register');
        }
      }
    );
    return () => {
      sessionCreatedPubSub.unsubscribe();
    };
  });

  //React lifecycle for listening the pubsub events when the header is loaded
  useEffect(() => {
    if (useLoginRegisterPopup && getCookie('registerSuccess')) {
      setSuccessType('register');
      removeCookie('registerSuccess');
    }
    if (olympic) {
      const firstVisit = getCookie('firstVisit');
      const currentPath = window.location.pathname;
      if (firstVisit === true || firstVisit === 'true') {
        if (currentPath === '/') {
          window.location.href = '/casino/';
        }
      }
      if (!firstVisit || firstVisit === 'false') {
        document.getElementById('topheader-left-logo-link').href = '/';
      }

      if (firstVisit === false || firstVisit === 'false') {
        if (currentPath === '/') {
          createCookie('firstVisit', true);
        }
      }
    }
    const session = FSBCustomerSession.getSession();
    let sessionUpdatedPubSub;
    if (session) {
      updateSession(session);
    } else {
      // Pubsub event listen for any update in the session
      sessionUpdatedPubSub = PubSub.listen(
        PubsubEvents.SESSION_UPDATED,
        wsData => {
          updateSession(wsData);
        }
      );
    }
    props.showMyBet(true);

    const sessionCreatedPubSub = PubSub.listen(
      PubsubEvents.SESSION_CREATED,
      wsData => {
        props.loginData(wsData);
        setShowHeaders(true);
        PubSub.emit(PubsubEvents.LoadCaptain, wsData);
        if (
          wsData.raw.accountStatus === 'SUSPENDED' &&
          wsData.raw.pepStatus === 'PENDING_REVIEW'
        ) {
          setPepModal(true);
        }
      }
    );

    // Pubsub event listen when the session is destroyed
    const sessionDestroy = PubSub.listen(PubsubEvents.SESSION_DESTROY, () => {
      // Function for deleting session data of customer
      PubSub.emit(PubsubEvents.THIRD_PARTY_TRACKING, {
        event: TRACKING_CONSTANTS.LOGOUT_SUCCESS,
        data: {},
      });
      props.loginData(null);
      setShowHeaders(true);
    });

    const sessionDestroyed = PubSub.listen(
      PubsubEvents.SESSION_DESTROYED,
      () => {
        // Function for deleting session data of customer
        props.loginData(null);
        setShowHeaders(true);
      }
    );

    // Pubsub event listen when the session is being created
    const sessionCreate = PubSub.listen(PubsubEvents.SESSION_CREATE, wsData => {
      updateStyle();
      // Function for sending the session data of customer
      props.loginData(wsData, props.showLoggedInTimer);
      props.getUserDetails(wsData.accessToken, props.showLoggedInTimer, wsData);
    });

    if (props.orisisAuth && window.location.pathname === '/my-account/') {
      if (
        document.getElementsByClassName('topheader-middle--link bet') &&
        document.getElementsByClassName('topheader-middle--link bet').length > 0
      )
        document.getElementsByClassName(
          'topheader-middle--link bet'
        )[0].style.display = 'none';
      props.showMyBet(true);
    }

    const betCount = PubSub.listen(PubsubEvents.BET_COUNT, count => {
      props.showMyBetCount(count);
    });

    const updateCustomer = PubSub.listen(
      PubsubEvents.UPDATE_CUSTOMER,
      token => {
        if (token)
          props.getUserDetails(token, false, FSBCustomerSession.getSession());
      }
    );

    if (!sessionToken) {
      setShowHeaders(true);
    }

    // Pubsub emit and listen to get balance update
    PubSub.emit('ws:sub:balance', sessionToken);

    const customerBalanceUpdated = PubSub.listen(
      PubsubEvents.CUSTOMER_BALANCE_UPDATE,
      wsData => {
        if (wsData?.encodedData) {
          const getArray = new Uint8Array(wsData.encodedData);
          const message = decode(getArray);
          if (
            message.gameRejectionCode &&
            message.gameRejectionCode === 'maxStakeForBonus'
          ) {
            PubSub.emit(PubsubEvents.THIRD_PARTY_TRACKING, {
              event: TRACKING_CONSTANTS.BONUS_BET_REJECTED,
              data: {
                errorCode: message.gameRejectionCode,
                product: 'casino',
                errorMessage: message.gameRejectionMessage,
              },
            });
          }
          if (
            message.insufficientBalance &&
            message.insufficientBalance === true
          ) {
            PubSub.emit(PubsubEvents.THIRD_PARTY_TRACKING, {
              event: TRACKING_CONSTANTS.BONUS_TOO_LOW,
              data: {},
            });
          }
        }
      }
    );

    const wsBalance = PubSub.listen('ws:balance', wsData => {
      if (
        wsData?.encodedData &&
        window.location.pathname !== customerData.account
      ) {
        const getArray = new Uint8Array(wsData.encodedData);
        const data = decode(getArray);
        if (data?.cashBalance) {
          props.updateAccountBalance(Number(data?.cashBalance));
        }
      }
    });

    const updateBalanceWithData = PubSub.listen(
      PubsubEvents.UpdateBalanceWithData,
      wsData => {
        props.updateAccountBalance(wsData.customer.balance);
      }
    );

    return () => {
      sessionCreatedPubSub.unsubscribe();
      sessionDestroy.unsubscribe();
      sessionDestroyed.unsubscribe();
      sessionCreate.unsubscribe();
      betCount.unsubscribe();
      updateCustomer.unsubscribe();
      customerBalanceUpdated.unsubscribe();
      wsBalance.unsubscribe();
      updateBalanceWithData.unsubscribe();
      sessionUpdatedPubSub?.unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const sessionCreated = PubSub.listen(PubsubEvents.SESSION_CREATED, () => {
      const UserAuthData = FSBCustomerSession.getSession();
      if (UserAuthData && UserAuthData.accessToken) {
        createCookie('userLoggedIn', true);
        createCookie(
          'pepStatePopUp',
          UserAuthData.raw.accountStatus === 'SUSPENDED' &&
            UserAuthData.raw.pepStatus === 'PENDING_REVIEW'
        );
      }
    });
    const sessionUpdated = PubSub.listen(PubsubEvents.SESSION_UPDATED, () => {
      const UserAuthData = FSBCustomerSession.getSession();
      if (UserAuthData && UserAuthData.accessToken) {
        createCookie('userLoggedIn', true);
      }
    });

    const unsettledBetCountListener = PubSub.listen(
      PubsubEvents.UpdateBalanceWithData,
      ({ customer }) => {
        const data = FSBCustomerSession.updateSession(customer);
        const { unsettledBetsCount } = customer || {};
        props?.setCustomerData({ ...data, unsettledBetsCount });
      }
    );

    const AppSession = getCookie('AppSession');
    if (!AppSession) {
      removeCookie('AppSession');
      removeCookie('userLoggedIn');
    }
    const realityCheckAckCookie = getCookie('realityCheckAckTime');
    const appSession = getCookie('AppSession');
    if (!appSession && realityCheckAckCookie) {
      removeCookie('realityCheckAckTime');
    }
    if (hideHeaderButtons) {
      setShowHeaders(false);
    }
    return () => {
      sessionCreated.unsubscribe();
      sessionUpdated.unsubscribe();
      unsettledBetCountListener?.unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // will only trigger when we have this particular condition from CMS
    // It is for adding an extra step to regular login as per casino time CR
    if (props.additionalLoginInfo) {
      const loginAcknowledgement = getCookie('loginPopupAcknowledged');
      const sessionToken = getCookie('AppSession');
      if (sessionToken && !loginAcknowledgement) {
        setLoginSuccessModal(true);
      } else {
        setLoginSuccessModal(false);
      }

      const sessionCreate = PubSub.listen(PubsubEvents.SESSION_CREATE, () => {
        setLoginSuccessModal(true);
      });

      //Clean pubsub on return
      return () => {
        sessionCreate?.unsubscribe();
      };
    }
  }, [props.additionalLoginInfo]);

  //Function for opening the login popup
  const openLogin = () => {
    // Pubsub event emitted for opening the login popup
    PubSub.emit(PubsubEvents.openLoginModal);
    // GTM event
    addCustomGTMEventToDataLayer({
      event: gtmEventNames.LOGIN_STARTED,
    });
  };
  //Function for opening the registration popup
  const openRegistration = () => {
    window.location.href = '/register/';
  };
  //Function for opening the customer my account popup
  const openMyAccount = () => {
    // Pubsub event emitted for opening the customer my account popup and sending the customer data
    PubSub.emit(PubsubEvents.openMyAccount, {
      starIconUrl: props.starIconIndicator,
    });
  };
  //Function for closing pepPopup
  const closePepPopUp = () => {
    setPepModal(false);
    createCookie('pepStatePopUp', false);
  };

  useEffect(() => {
    const listener = PubSub.listen(PubsubEvents.SESSION_CREATED, () => {
      const sessionToken = getCookie('AppSession');
      dispatch(getCustomerCard(sessionToken));
    });
    const sessionToken = getCookie('AppSession');
    dispatch(getCustomerCard(sessionToken));
    return () => {
      listener.unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FSBTheme theme={theme}>
      <React.Fragment>
        {props.additionalLoginInfo && loginSuccessModal && (
          <SuccessLoginPopup
            successType={successType}
            additionalInfoLimitsHtml={additionalInfoLimitsHtml}
            loginSuccessModal={loginSuccessModal}
            setLoginSuccessModal={setLoginSuccessModal}
            includeLimitsLink={props.appConfig.includelimitslink}
            gameLimit={props?.appConfig?.gamelimit}
          />
        )}
        {showHeaders && (
          <AuthHeader
            appConfig={props.appConfig}
            openLogin={openLogin}
            openRegistration={openRegistration}
            openMyAccount={openMyAccount}
            showBalance={props.showBalance}
            showLoggedInTimer={props.showLoggedInTimer}
            starIconIndicator={props.starIconIndicator}
          />
        )}
        {pepModal && (
          <CC>
            <PepPopup closePopup={closePepPopUp} />
          </CC>
        )}
        <SessionExpiry />
      </React.Fragment>
    </FSBTheme>
  );
};

AuthorizationHeaderReact.propTypes = {
  appConfig: PropTypes.object,
  loginData: PropTypes.func,
  openLogout: PropTypes.func,
  logoutPopup: PropTypes.bool,
  showBalance: PropTypes.func,
  showMyBet: PropTypes.func,
  orisisAuth: PropTypes.bool,
  showMyBetCount: PropTypes.func,
  getUserDetails: PropTypes.func,
  updateAccountBalance: PropTypes.func,
  setCustomerData: PropTypes.func,
  theme: PropTypes.string,
  additionalLoginInfo: PropTypes.bool,
  showLoggedInTimer: PropTypes.bool,
  starIconIndicator: PropTypes.string,
};
