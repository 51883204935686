export const appConfig = {
  appid: 1356337496,
  ssr: false,
  app: 'authorizationApp',
  state: 'IN_PLAY',
  title: 'Live Now',
  offset: 240,
  market: '1x2,MWIN,OU,WMON,1X2OT,ET1x2,1x2BTTS,TGW,WWPGS',
  groupby: 'date_x',
  columns: 2,
  LOGIN_ACK: 'loginPopupAcknowledged',
  requestURL: 'myNameIsUrl',
};

export const EYE = {
  OPEN_EYE: 'OPEN_EYE',
  CLOSED_EYE: 'CLOSED_EYE',
  HIDE: '****',
  EXIT: 'EXIT',
  LOGIN: 'LOGIN',
};
export const finalReduxState = {
  appConfig: {
    appid: 1356337496,
    ssr: false,
    app: 'EventsApp',
    state: 'IN_PLAY',
    title: 'Live Now',
    offset: 240,
    market: '1x2,MWIN,OU,WMON,1X2OT,ET1x2,1x2BTTS,TGW,WWPGS',
    groupby: 'date_x',
    columns: 2,
    categorytab: true,
    eventsHeaderActive: true,
  },
  categories: { SOCCER: 'Football', TENNIS: 'Tennis' },
  activeTabCategory: {
    id: 1,
    name: 'Football',
    ref: 'SOCCER',
    subcat: [],
    events_count: 3,
  },
  SOCCER: {
    events: {
      id: 1,
      name: 'Football',
      ref: 'SOCCER',
      subcat: [
        {
          event: [
            {
              id: 5922128,
              name: 'Arhavispor v Elazig Belediyespor',
              scheduledStart: 1611828000000,
              toDisplayMarket: {
                OU: {
                  active: true,
                  handicap: 3.5,
                  name: 'Total Goals - Over/Under 3.5',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 516484579,
                      name: 'Under 3.5 goals',
                      nonRunner: false,
                      ordinal: 0,
                      price: [
                        {
                          bookId: 90781666,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.25,
                          fractional: '1/4',
                          id: 1938001847,
                        },
                      ],
                      typeRef: 'UNDER',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 516484580,
                      name: 'Over 3.5 goals',
                      nonRunner: false,
                      ordinal: 1,
                      price: [
                        {
                          bookId: 90781666,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 3.6,
                          fractional: '13/5',
                          id: 1938001848,
                        },
                      ],
                      typeRef: 'OVER',
                      version: 0,
                    },
                  ],
                  typeName: 'Over/Under',
                  typeRef: 'OU',
                  version: 0,
                },
                '1x2': {
                  active: true,
                  handicap: 0,
                  name: 'Match Result',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 516484585,
                      name: 'Arhavispor',
                      nonRunner: false,
                      ordinal: 0,
                      price: [
                        {
                          bookId: 90781669,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.0303,
                          fractional: '1/33',
                          id: 1937990397,
                        },
                      ],
                      sourceKey: '132149',
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 516484586,
                      name: 'Draw',
                      nonRunner: false,
                      ordinal: 1,
                      price: [
                        {
                          bookId: 90781669,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 8,
                          fractional: '7/1',
                          id: 1937993490,
                        },
                      ],
                      typeRef: 'DRAW',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 516484587,
                      name: 'Elazig Belediyespor',
                      nonRunner: false,
                      ordinal: 2,
                      price: [
                        {
                          bookId: 90781669,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 29,
                          fractional: '28/1',
                          id: 1937993491,
                        },
                      ],
                      sourceKey: '686890',
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: '1x2',
                  typeRef: '1x2',
                  version: 0,
                },
              },
              toDisplaySelection: {
                OU: [
                  { typeRef: 'UNDER', name: 'UNDER' },
                  { typeRef: 'OVER', name: 'OVER' },
                ],
                '1x2': [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'DRAW', name: 'DRAW' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
            {
              id: 5922125,
              name: 'Cankaya FK v Kizilcabölükspor',
              scheduledStart: 1611828000000,
              toDisplayMarket: {
                '1x2': {
                  active: true,
                  handicap: 0,
                  name: 'Match Result',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 516484298,
                      name: 'Cankaya FK',
                      nonRunner: false,
                      ordinal: 0,
                      price: [
                        {
                          bookId: 90781579,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 12,
                          fractional: '11/1',
                          id: 1937997698,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 516484299,
                      name: 'Draw',
                      nonRunner: false,
                      ordinal: 1,
                      price: [
                        {
                          bookId: 90781579,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 4.5,
                          fractional: '7/2',
                          id: 1937995658,
                        },
                      ],
                      typeRef: 'DRAW',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 516484300,
                      name: 'Kizilcabölükspor',
                      nonRunner: false,
                      ordinal: 2,
                      price: [
                        {
                          bookId: 90781579,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.25,
                          fractional: '1/4',
                          id: 1937995411,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: '1x2',
                  typeRef: '1x2',
                  version: 0,
                },
              },
              toDisplaySelection: {
                '1x2': [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'DRAW', name: 'DRAW' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
            {
              id: 5922137,
              name: 'Karsiyaka v Isparta DavrazSpor',
              scheduledStart: 1611828000000,
              toDisplayMarket: {
                '1x2': {
                  active: true,
                  handicap: 0,
                  name: 'Match Result',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 516485172,
                      name: 'Karsiyaka',
                      nonRunner: false,
                      ordinal: 0,
                      price: [
                        {
                          bookId: 90781775,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 5.5,
                          fractional: '9/2',
                          id: 1938001549,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 516485173,
                      name: 'Draw',
                      nonRunner: false,
                      ordinal: 1,
                      price: [
                        {
                          bookId: 90781775,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.833,
                          fractional: '5/6',
                          id: 1938001550,
                        },
                      ],
                      typeRef: 'DRAW',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 516485174,
                      name: 'Isparta DavrazSpor',
                      nonRunner: false,
                      ordinal: 2,
                      price: [
                        {
                          bookId: 90781775,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 2.75,
                          fractional: '7/4',
                          id: 1937998462,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: '1x2',
                  typeRef: '1x2',
                  version: 0,
                },
              },
              toDisplaySelection: {
                '1x2': [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'DRAW', name: 'DRAW' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
            {
              id: 5922132,
              name: 'Sile Yildizspor v Yeni Altindag BS',
              scheduledStart: 1611828060000,
              toDisplayMarket: {
                OU: {
                  active: true,
                  handicap: 1.5,
                  name: 'Total Goals - Over/Under 1.5',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 516484916,
                      name: 'Under 1.5 goals',
                      nonRunner: false,
                      ordinal: 0,
                      price: [
                        {
                          bookId: 90781734,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.7,
                          fractional: '7/10',
                          id: 1938001890,
                        },
                      ],
                      typeRef: 'UNDER',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 516484917,
                      name: 'Over 1.5 goals',
                      nonRunner: false,
                      ordinal: 1,
                      price: [
                        {
                          bookId: 90781734,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 2,
                          fractional: 'Evs',
                          id: 1938001891,
                        },
                      ],
                      typeRef: 'OVER',
                      version: 0,
                    },
                  ],
                  typeName: 'Over/Under',
                  typeRef: 'OU',
                  version: 0,
                },
                '1x2': {
                  active: true,
                  handicap: 0,
                  name: 'Match Result',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 516484926,
                      name: 'Sile Yildizspor',
                      nonRunner: false,
                      ordinal: 0,
                      price: [
                        {
                          bookId: 90781739,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.1667,
                          fractional: '1/6',
                          id: 1938001899,
                        },
                      ],
                      sourceKey: '858300',
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 516484927,
                      name: 'Draw',
                      nonRunner: false,
                      ordinal: 1,
                      price: [
                        {
                          bookId: 90781739,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 4.75,
                          fractional: '15/4',
                          id: 1937998566,
                        },
                      ],
                      typeRef: 'DRAW',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 516484928,
                      name: 'Yeni Altindag BS',
                      nonRunner: false,
                      ordinal: 2,
                      price: [
                        {
                          bookId: 90781739,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 15,
                          fractional: '14/1',
                          id: 1937990898,
                        },
                      ],
                      sourceKey: '796849',
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: '1x2',
                  typeRef: '1x2',
                  version: 0,
                },
              },
              toDisplaySelection: {
                OU: [
                  { typeRef: 'UNDER', name: 'UNDER' },
                  { typeRef: 'OVER', name: 'OVER' },
                ],
                '1x2': [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'DRAW', name: 'DRAW' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
            {
              id: 5922138,
              name: 'Somaspor v Erokspor',
              scheduledStart: 1611828000000,
              toDisplayMarket: {
                '1x2': {
                  active: true,
                  handicap: 0,
                  name: 'Match Result',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 516485224,
                      name: 'Somaspor',
                      nonRunner: false,
                      ordinal: 0,
                      price: [
                        {
                          bookId: 90781787,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.25,
                          fractional: '1/4',
                          id: 1937997370,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 516485225,
                      name: 'Draw',
                      nonRunner: false,
                      ordinal: 1,
                      price: [
                        {
                          bookId: 90781787,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 4.5,
                          fractional: '7/2',
                          id: 1937995902,
                        },
                      ],
                      typeRef: 'DRAW',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 516485226,
                      name: 'Erokspor',
                      nonRunner: false,
                      ordinal: 2,
                      price: [
                        {
                          bookId: 90781787,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 11,
                          fractional: '10/1',
                          id: 1937997371,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: '1x2',
                  typeRef: '1x2',
                  version: 0,
                },
              },
              toDisplaySelection: {
                '1x2': [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'DRAW', name: 'DRAW' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
          ],
          featured: false,
          id: 4952,
          name: 'Turkey 3. Lig',
          ref: 'TURKEY_3_LIG',
        },
        {
          countryCode: 'VN',
          countryName: 'Vietnam',
          event: [
            {
              id: 5928388,
              name: 'Binh Phuoc U19 v Tien Giang U19',
              scheduledStart: 1611828000000,
              toDisplayMarket: {
                OU: {
                  active: true,
                  handicap: 2.5,
                  name: 'Total Goals - Over/Under 2.5',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 516951172,
                      name: 'Under 2.5 goals',
                      nonRunner: false,
                      ordinal: 0,
                      price: [
                        {
                          bookId: 90877442,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.2857,
                          fractional: '2/7',
                          id: 1938001642,
                        },
                      ],
                      typeRef: 'UNDER',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 516951173,
                      name: 'Over 2.5 goals',
                      nonRunner: false,
                      ordinal: 1,
                      price: [
                        {
                          bookId: 90877442,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 3.3,
                          fractional: '23/10',
                          id: 1937997965,
                        },
                      ],
                      typeRef: 'OVER',
                      version: 0,
                    },
                  ],
                  typeName: 'Over/Under',
                  typeRef: 'OU',
                  version: 0,
                },
                '1x2': {
                  active: true,
                  handicap: 0,
                  name: 'Match Result',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 516951180,
                      name: 'Binh Phuoc U19',
                      nonRunner: false,
                      ordinal: 0,
                      price: [
                        {
                          bookId: 90877446,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.1818,
                          fractional: '2/11',
                          id: 1937982008,
                        },
                      ],
                      sourceKey: '225733',
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 516951181,
                      name: 'Draw',
                      nonRunner: false,
                      ordinal: 1,
                      price: [
                        {
                          bookId: 90877446,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 5,
                          fractional: '4/1',
                          id: 1937997972,
                        },
                      ],
                      typeRef: 'DRAW',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 516951182,
                      name: 'Tien Giang U19',
                      nonRunner: false,
                      ordinal: 2,
                      price: [
                        {
                          bookId: 90877446,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 15,
                          fractional: '14/1',
                          id: 1937997973,
                        },
                      ],
                      sourceKey: '1379353',
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: '1x2',
                  typeRef: '1x2',
                  version: 0,
                },
              },
              toDisplaySelection: {
                OU: [
                  { typeRef: 'UNDER', name: 'UNDER' },
                  { typeRef: 'OVER', name: 'OVER' },
                ],
                '1x2': [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'DRAW', name: 'DRAW' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
            {
              id: 5928389,
              name: 'Huda Hue U19 v Da Nang U19',
              scheduledStart: 1611828000000,
              toDisplayMarket: {
                OU: {
                  active: false,
                  handicap: 6.5,
                  name: 'Total Goals - Over/Under 6.5',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517172890,
                      name: 'Under 6.5 goals',
                      nonRunner: false,
                      ordinal: 0,
                      price: [
                        {
                          bookId: 90923901,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.001,
                          fractional: '1/1000',
                          id: 1937908785,
                        },
                      ],
                      typeRef: 'UNDER',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517172891,
                      name: 'Over 6.5 goals',
                      nonRunner: false,
                      ordinal: 1,
                      price: [
                        {
                          bookId: 90923901,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 12,
                          fractional: '11/1',
                          id: 1937908417,
                        },
                      ],
                      typeRef: 'OVER',
                      version: 0,
                    },
                  ],
                  typeName: 'Over/Under',
                  typeRef: 'OU',
                  version: 0,
                },
                '1x2': {
                  active: false,
                  handicap: 0,
                  name: 'Match Result',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 516951352,
                      name: 'Huda Hue U19',
                      nonRunner: false,
                      ordinal: 0,
                      price: [
                        {
                          bookId: 90877471,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 41,
                          fractional: '40/1',
                          id: 1937908424,
                        },
                      ],
                      sourceKey: '199276',
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 516951353,
                      name: 'Draw',
                      nonRunner: false,
                      ordinal: 1,
                      price: [
                        {
                          bookId: 90877471,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 11,
                          fractional: '10/1',
                          id: 1937882159,
                        },
                      ],
                      typeRef: 'DRAW',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 516951354,
                      name: 'Da Nang U19',
                      nonRunner: false,
                      ordinal: 2,
                      price: [
                        {
                          bookId: 90877471,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.0002,
                          fractional: '1/5000',
                          id: 1937908425,
                        },
                      ],
                      sourceKey: '192716',
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: '1x2',
                  typeRef: '1x2',
                  version: 0,
                },
              },
              toDisplaySelection: {
                OU: [
                  { typeRef: 'UNDER', name: 'UNDER' },
                  { typeRef: 'OVER', name: 'OVER' },
                ],
                '1x2': [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'DRAW', name: 'DRAW' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
          ],
          featured: false,
          id: 928,
          name: 'Vietnam U19 Championship',
          ref: 'VIETNAM_U19_CHAMPIONSHIP',
        },
        {
          event: [
            {
              id: 5921216,
              name: 'CSA Steaua Bucuresti v Universitatea Cluj',
              scheduledStart: 1611828000000,
              toDisplayMarket: {
                '1x2': {
                  active: true,
                  handicap: 0,
                  name: 'Match Result',
                  selection: [
                    {
                      active: false,
                      displayed: true,
                      id: 516410485,
                      name: 'CSA Steaua Bucuresti',
                      nonRunner: false,
                      ordinal: 0,
                      price: [
                        {
                          bookId: 90766347,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.001,
                          fractional: '1/1000',
                          id: 1938001141,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 516410486,
                      name: 'Draw',
                      nonRunner: false,
                      ordinal: 1,
                      price: [
                        {
                          bookId: 90766347,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 46,
                          fractional: '45/1',
                          id: 1938001142,
                        },
                      ],
                      typeRef: 'DRAW',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 516410487,
                      name: 'Universitatea Cluj',
                      nonRunner: false,
                      ordinal: 2,
                      price: [
                        {
                          bookId: 90766347,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 226,
                          fractional: '225/1',
                          id: 1938001143,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: '1x2',
                  typeRef: '1x2',
                  version: 0,
                },
              },
              toDisplaySelection: {
                '1x2': [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'DRAW', name: 'DRAW' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
            {
              id: 5920522,
              name: 'CSKA Moskva v Real Murcia Imperial',
              scheduledStart: 1611828000000,
              toDisplayMarket: {
                '1x2': {
                  active: false,
                  handicap: 0,
                  name: 'Match Result',
                  selection: [
                    {
                      active: false,
                      displayed: true,
                      id: 516357059,
                      name: 'CSKA Moskva',
                      nonRunner: false,
                      ordinal: 0,
                      price: [
                        {
                          bookId: 90755526,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.002,
                          fractional: '1/500',
                          id: 1937971575,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: false,
                      displayed: true,
                      id: 516357060,
                      name: 'Draw',
                      nonRunner: false,
                      ordinal: 1,
                      price: [
                        {
                          bookId: 90755526,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 51,
                          fractional: '50/1',
                          id: 1937971576,
                        },
                      ],
                      typeRef: 'DRAW',
                      version: 0,
                    },
                    {
                      active: false,
                      displayed: true,
                      id: 516357061,
                      name: 'Real Murcia Imperial',
                      nonRunner: false,
                      ordinal: 2,
                      price: [
                        {
                          bookId: 90755526,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 51,
                          fractional: '50/1',
                          id: 1937971577,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: '1x2',
                  typeRef: '1x2',
                  version: 0,
                },
              },
              toDisplaySelection: {
                '1x2': [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'DRAW', name: 'DRAW' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
            {
              id: 5920541,
              name: 'Woombye Snakes v Sunshine Coast',
              scheduledStart: 1611828000000,
              toDisplayMarket: {
                '1x2': {
                  active: true,
                  handicap: 0,
                  name: 'Match Result',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 516358030,
                      name: 'Woombye Snakes',
                      nonRunner: false,
                      ordinal: 0,
                      price: [
                        {
                          bookId: 90755714,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 29,
                          fractional: '28/1',
                          id: 1937946292,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 516358031,
                      name: 'Draw',
                      nonRunner: false,
                      ordinal: 1,
                      price: [
                        {
                          bookId: 90755714,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 13,
                          fractional: '12/1',
                          id: 1937942577,
                        },
                      ],
                      typeRef: 'DRAW',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 516358032,
                      name: 'Sunshine Coast',
                      nonRunner: false,
                      ordinal: 2,
                      price: [
                        {
                          bookId: 90755714,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.04,
                          fractional: '1/25',
                          id: 1937965889,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: '1x2',
                  typeRef: '1x2',
                  version: 0,
                },
              },
              toDisplaySelection: {
                '1x2': [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'DRAW', name: 'DRAW' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
          ],
          featured: false,
          id: 5853,
          name: 'Club Friendlies',
          ref: 'CLUB_FRIENDLIES',
        },
      ],
      events_count: 3,
    },
    marketDropdown: {
      '1x2': 'Match Result',
      OU: 'Over/Under',
      '1x2BTTS': '1x2BTTS',
    },
    selectedmarketDropdown: ['1x2', 'OU'],
  },
  TENNIS: {
    events: {
      id: 3,
      name: 'Tennis',
      ref: 'TENNIS',
      subcat: [
        {
          event: [
            {
              id: 5921963,
              name: 'Filip Horansky v Maximilian Marterer',
              scheduledStart: 1611828000000,
              toDisplayMarket: {
                TGW: {
                  active: true,
                  handicap: 0,
                  name: 'Who will win game 4 of set 2?',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517162342,
                      name: 'Filip Horansky',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90935638,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 6,
                          fractional: '5/1',
                          id: 1938001424,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517162343,
                      name: 'Maximilian Marterer',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90935638,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.12,
                          fractional: '1/8',
                          id: 1938001425,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Tennis Game Winner',
                  typeRef: 'TGW',
                  version: 0,
                },
                MWIN: {
                  active: true,
                  handicap: 0,
                  name: 'Match Winner',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 516471322,
                      name: 'Filip Horansky',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90778762,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 5.5,
                          fractional: '9/2',
                          id: 1938001379,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 516471323,
                      name: 'Maximilian Marterer',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90778762,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.15,
                          fractional: '2/13',
                          id: 1938001380,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Match Winner',
                  typeRef: 'MWIN',
                  version: 0,
                },
                WWPGS: {
                  active: true,
                  handicap: 0,
                  name: 'Point Winner - Point 2, Game 4, Set 2',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517162357,
                      name: 'Filip Horansky',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90935640,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 2.8,
                          fractional: '9/5',
                          id: 1938001439,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517162358,
                      name: 'Maximilian Marterer',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90935640,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.45,
                          fractional: '5/11',
                          id: 1938001440,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Who wins point in game of set',
                  typeRef: 'WWPGS',
                  version: 0,
                },
              },
              toDisplaySelection: {
                TGW: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                MWIN: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                WWPGS: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
          ],
          featured: false,
          id: 4722,
          name: 'ATP Challenger Quimper',
          ref: 'ATP_CHALLENGER_QUIMPER',
        },
        {
          event: [
            {
              id: 5921795,
              name: 'Eva Vedder v Yvonne Cavalle-Reimers',
              scheduledStart: 1611829800000,
              toDisplayMarket: {
                TGW: {
                  active: true,
                  handicap: 0,
                  name: 'Who will win game 1 of set 2?',
                  selection: [
                    {
                      active: false,
                      displayed: true,
                      id: 517188281,
                      name: 'Eva Vedder',
                      nonRunner: false,
                      ordinal: 4,
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: false,
                      displayed: true,
                      id: 517188282,
                      name: 'Yvonne Cavalle-Reimers',
                      nonRunner: false,
                      ordinal: 5,
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Tennis Game Winner',
                  typeRef: 'TGW',
                  version: 0,
                },
                MWIN: {
                  active: true,
                  handicap: 0,
                  name: 'Match Winner',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517153457,
                      name: 'Eva Vedder',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90919622,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 9,
                          fractional: '8/1',
                          id: 1938001997,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517153458,
                      name: 'Yvonne Cavalle-Reimers',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90919622,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.02,
                          fractional: '1/50',
                          id: 1938001998,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Match Winner',
                  typeRef: 'MWIN',
                  version: 0,
                },
                WWPGS: {
                  active: false,
                  handicap: 0,
                  name: 'Point Winner - Point 1, Game 2, Set 2',
                  selection: [
                    {
                      active: false,
                      displayed: true,
                      id: 517188283,
                      name: 'Eva Vedder',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90935313,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 2.2,
                          fractional: '6/5',
                          id: 1937997930,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: false,
                      displayed: true,
                      id: 517188284,
                      name: 'Yvonne Cavalle-reimers',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90935313,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.6,
                          fractional: '6/10',
                          id: 1937997931,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Who wins point in game of set',
                  typeRef: 'WWPGS',
                  version: 0,
                },
              },
              toDisplaySelection: {
                TGW: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                MWIN: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                WWPGS: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
            {
              id: 5921799,
              name: 'Ylena In-Albon v Suzan Lamens',
              scheduledStart: 1611829200000,
              toDisplayMarket: {
                TGW: {
                  active: true,
                  handicap: 0,
                  name: 'Who will win game 1 of set 2?',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517174724,
                      name: 'Ylena In-Albon',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90934214,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 2,
                          fractional: 'Evs',
                          id: 1937993731,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517174725,
                      name: 'Suzan Lamens',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90934214,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.65,
                          fractional: '13/20',
                          id: 1937988611,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Tennis Game Winner',
                  typeRef: 'TGW',
                  version: 0,
                },
                WWPGS: {
                  active: true,
                  handicap: 0,
                  name: 'Point Winner - Point 2, Game 1, Set 2',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517174726,
                      name: 'Ylena In-albon',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90934215,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.9,
                          fractional: '9/10',
                          id: 1937979437,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517174727,
                      name: 'Suzan Lamens',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90934215,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.75,
                          fractional: '3/4',
                          id: 1937988612,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Who wins point in game of set',
                  typeRef: 'WWPGS',
                  version: 0,
                },
                MWIN: {
                  active: true,
                  handicap: 0,
                  name: 'Match Winner',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517170493,
                      name: 'Ylena In-Albon',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90923351,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.12,
                          fractional: '1/8',
                          id: 1937988603,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517170494,
                      name: 'Suzan Lamens',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90923351,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 5,
                          fractional: '4/1',
                          id: 1937988604,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Match Winner',
                  typeRef: 'MWIN',
                  version: 0,
                },
              },
              toDisplaySelection: {
                TGW: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                WWPGS: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                MWIN: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
            {
              id: 5921798,
              name: 'Alba Carrillo-Marin v Alexandra Eala',
              scheduledStart: 1611829800000,
              toDisplayMarket: {
                TGW: {
                  active: true,
                  handicap: 0,
                  name: 'Who will win game 3 of set 2?',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517191106,
                      name: 'Alba Carrillo-Marin',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90935201,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 3.5,
                          fractional: '5/2',
                          id: 1938001257,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517191107,
                      name: 'Alexandra Eala',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90935201,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.25,
                          fractional: '1/4',
                          id: 1937998535,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Tennis Game Winner',
                  typeRef: 'TGW',
                  version: 0,
                },
                MWIN: {
                  active: true,
                  handicap: 0,
                  name: 'Match Winner',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517186278,
                      name: 'Alba Carrillo-Marin',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90927140,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 10,
                          fractional: '9/1',
                          id: 1937998528,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517186279,
                      name: 'Alexandra Eala',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90927140,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.01,
                          fractional: '1/100',
                          id: 1937995886,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Match Winner',
                  typeRef: 'MWIN',
                  version: 0,
                },
                WWPGS: {
                  active: true,
                  handicap: 0,
                  name: 'Point Winner - Point 3, Game 3, Set 2',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517191108,
                      name: 'Alba Carrillo-Marin',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90935326,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 2,
                          fractional: 'Evs',
                          id: 1937998536,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517191109,
                      name: 'Alexandra Eala',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90935326,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.7,
                          fractional: '7/10',
                          id: 1937998537,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Who wins point in game of set',
                  typeRef: 'WWPGS',
                  version: 0,
                },
              },
              toDisplaySelection: {
                TGW: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                MWIN: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                WWPGS: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
            {
              id: 5921793,
              name: 'Valentina Ryser v Maria Gutierrez-Carrasco',
              scheduledStart: 1611828000000,
              toDisplayMarket: {
                MWIN: {
                  active: true,
                  handicap: 0,
                  name: 'Match Winner',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517152045,
                      name: 'Valentina Ryser',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90919317,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 6.5,
                          fractional: '11/2',
                          id: 1938001652,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517152046,
                      name: 'Maria Gutierrez-Carrasco',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90919317,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.07,
                          fractional: '1/14',
                          id: 1938001653,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Match Winner',
                  typeRef: 'MWIN',
                  version: 0,
                },
                TGW: {
                  active: true,
                  handicap: 0,
                  name: 'Who will win game 9 of set 2?',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517159290,
                      name: 'Valentina Ryser',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90935327,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.65,
                          fractional: '13/20',
                          id: 1938001659,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517159291,
                      name: 'Maria Gutierrez-Carrasco',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90935327,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 2.1,
                          fractional: '11/10',
                          id: 1938001660,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Tennis Game Winner',
                  typeRef: 'TGW',
                  version: 0,
                },
                WWPGS: {
                  active: true,
                  handicap: 0,
                  name: 'Point Winner - Point 3, Game 9, Set 2',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517159292,
                      name: 'Valentina Ryser',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90935645,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 2,
                          fractional: 'Evs',
                          id: 1938001661,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517159293,
                      name: 'Maria Gutierrez-Carrasco',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90935645,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.7,
                          fractional: '7/10',
                          id: 1938001662,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Who wins point in game of set',
                  typeRef: 'WWPGS',
                  version: 0,
                },
              },
              toDisplaySelection: {
                MWIN: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                TGW: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                WWPGS: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
            {
              id: 5921797,
              name: 'Kamilla Bartone v Shiho Akita',
              scheduledStart: 1611828000000,
              toDisplayMarket: {
                WWPGS: {
                  active: true,
                  handicap: 0,
                  name: 'Point Winner - Point 4, Game 5, Set 2',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517162294,
                      name: 'Kamilla Bartone',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90935646,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 2,
                          fractional: 'Evs',
                          id: 1938001737,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517162295,
                      name: 'Shiho Akita',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90935646,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.65,
                          fractional: '13/20',
                          id: 1938001738,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Who wins point in game of set',
                  typeRef: 'WWPGS',
                  version: 0,
                },
                TGW: {
                  active: true,
                  handicap: 0,
                  name: 'Who will win game 5 of set 2?',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517162292,
                      name: 'Kamilla Bartone',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90935295,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 2.4,
                          fractional: '7/5',
                          id: 1938001735,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517162293,
                      name: 'Shiho Akita',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90935295,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.5,
                          fractional: '1/2',
                          id: 1938001736,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Tennis Game Winner',
                  typeRef: 'TGW',
                  version: 0,
                },
                MWIN: {
                  active: true,
                  handicap: 0,
                  name: 'Match Winner',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517162282,
                      name: 'Kamilla Bartone',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90921461,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 3.5,
                          fractional: '5/2',
                          id: 1938001728,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517162283,
                      name: 'Shiho Akita',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90921461,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.25,
                          fractional: '1/4',
                          id: 1938001729,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Match Winner',
                  typeRef: 'MWIN',
                  version: 0,
                },
              },
              toDisplaySelection: {
                WWPGS: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                TGW: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                MWIN: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
          ],
          featured: false,
          id: 4733,
          name: 'ITF Spain 02A',
          ref: 'ITF_SPAIN_02A',
        },
        {
          event: [
            {
              id: 5921011,
              name: 'Cristina Bucsa v Leonie Kung',
              scheduledStart: 1611829200000,
              toDisplayMarket: {
                WWPGS: {
                  active: true,
                  handicap: 0,
                  name: 'Point Winner - Point 1, Game 9, Set 2',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517177118,
                      name: 'Cristina Bucsa',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90934823,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.8,
                          fractional: '4/5',
                          id: 1938001275,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517177119,
                      name: 'Leonie Kung',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90934823,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.9,
                          fractional: '9/10',
                          id: 1937998764,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Who wins point in game of set',
                  typeRef: 'WWPGS',
                  version: 0,
                },
                MWIN: {
                  active: true,
                  handicap: 0,
                  name: 'Match Winner',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517177106,
                      name: 'Cristina Bucsa',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90924937,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 9.5,
                          fractional: '17/2',
                          id: 1938001808,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517177107,
                      name: 'Leonie Kung',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90924937,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.02,
                          fractional: '1/50',
                          id: 1938001809,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Match Winner',
                  typeRef: 'MWIN',
                  version: 0,
                },
                TGW: {
                  active: true,
                  handicap: 0,
                  name: 'Who will win game 8 of set 2?',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517177116,
                      name: 'Cristina Bucsa',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90934246,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 5.5,
                          fractional: '9/2',
                          id: 1938001815,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517177117,
                      name: 'Leonie Kung',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90934246,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.12,
                          fractional: '1/8',
                          id: 1938001816,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Tennis Game Winner',
                  typeRef: 'TGW',
                  version: 0,
                },
              },
              toDisplaySelection: {
                WWPGS: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                MWIN: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                TGW: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
            {
              id: 5924183,
              name: 'Viktoriya Tomova v Kathinka von-Deichmann',
              scheduledStart: 1611831300000,
              toDisplayMarket: {
                MWIN: {
                  active: false,
                  handicap: 0,
                  name: 'Match Winner',
                  selection: [
                    {
                      active: false,
                      displayed: true,
                      id: 517200862,
                      name: 'Viktoriya Tomova',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90930442,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 3,
                          fractional: '2/1',
                          id: 1938001504,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: false,
                      displayed: true,
                      id: 517200863,
                      name: 'Kathinka von-Deichmann',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90930442,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.3,
                          fractional: '3/10',
                          id: 1938001505,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Match Winner',
                  typeRef: 'MWIN',
                  version: 0,
                },
                WWPGS: {
                  active: false,
                  handicap: 0,
                  name: 'Point Winner - Point 2, Game 6, Set 1',
                  selection: [
                    {
                      active: false,
                      displayed: true,
                      id: 517203413,
                      name: 'Viktoriya Tomova',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90935644,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 2,
                          fractional: 'Evs',
                          id: 1938001950,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: false,
                      displayed: true,
                      id: 517203414,
                      name: 'Kathinka von-Deichmann',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90935644,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.65,
                          fractional: '13/20',
                          id: 1938001951,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Who wins point in game of set',
                  typeRef: 'WWPGS',
                  version: 0,
                },
                TGW: {
                  active: false,
                  handicap: 0,
                  name: 'Who will win game 6 of set 1?',
                  selection: [
                    {
                      active: false,
                      displayed: true,
                      id: 517205749,
                      name: 'Viktoriya Tomova',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90935643,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 2.4,
                          fractional: '7/5',
                          id: 1938001948,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: false,
                      displayed: true,
                      id: 517205750,
                      name: 'Kathinka von-Deichmann',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90935643,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.5,
                          fractional: '1/2',
                          id: 1938001949,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Tennis Game Winner',
                  typeRef: 'TGW',
                  version: 0,
                },
              },
              toDisplaySelection: {
                MWIN: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                WWPGS: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                TGW: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
          ],
          featured: false,
          id: 4647,
          name: 'ITF France 01A',
          ref: 'ITF_FRANCE_01A',
        },
        {
          event: [
            {
              id: 5920657,
              name: 'Gonzalo Lama v Aristotelis Thanos',
              scheduledStart: 1611828900000,
              toDisplayMarket: {
                WWPGS: {
                  active: false,
                  handicap: 0,
                  name: 'Point Winner - Point 2, Game 6, Set 2',
                  selection: [
                    {
                      active: false,
                      displayed: true,
                      id: 517173096,
                      name: 'Gonzalo Lama',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90935302,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.8,
                          fractional: '4/5',
                          id: 1937997534,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: false,
                      displayed: true,
                      id: 517173097,
                      name: 'Aristotelis Thanos',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90935302,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.85,
                          fractional: '17/20',
                          id: 1937997535,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Who wins point in game of set',
                  typeRef: 'WWPGS',
                  version: 0,
                },
                MWIN: {
                  active: false,
                  handicap: 0,
                  name: 'Match Winner',
                  selection: [
                    {
                      active: false,
                      displayed: true,
                      id: 517173080,
                      name: 'Gonzalo Lama',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90923961,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.01,
                          fractional: '1/100',
                          id: 1937969233,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: false,
                      displayed: true,
                      id: 517173081,
                      name: 'Aristotelis Thanos',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90923961,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 11,
                          fractional: '10/1',
                          id: 1937984769,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Match Winner',
                  typeRef: 'MWIN',
                  version: 0,
                },
                TGW: {
                  active: false,
                  handicap: 0,
                  name: 'Who will win game 6 of set 2?',
                  selection: [
                    {
                      active: false,
                      displayed: true,
                      id: 517173088,
                      name: 'Gonzalo Lama',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90935301,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.75,
                          fractional: '3/4',
                          id: 1937997532,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: false,
                      displayed: true,
                      id: 517173089,
                      name: 'Aristotelis Thanos',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90935301,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.9,
                          fractional: '9/10',
                          id: 1937997533,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Tennis Game Winner',
                  typeRef: 'TGW',
                  version: 0,
                },
              },
              toDisplaySelection: {
                WWPGS: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                MWIN: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                TGW: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
            {
              id: 5920660,
              name: 'Lenny Hampel v Naoki Tajima',
              scheduledStart: 1611829800000,
              toDisplayMarket: {
                WWPGS: {
                  active: true,
                  handicap: 0,
                  name: 'Point Winner - Point 2, Game 4, Set 2',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517181497,
                      name: 'Lenny Hampel',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90935630,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.5,
                          fractional: '1/2',
                          id: 1938001299,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517181498,
                      name: 'Naoki Tajima',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90935630,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 2.4,
                          fractional: '7/5',
                          id: 1938001067,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Who wins point in game of set',
                  typeRef: 'WWPGS',
                  version: 0,
                },
                TGW: {
                  active: true,
                  handicap: 0,
                  name: 'Who will win game 4 of set 2?',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517181495,
                      name: 'Lenny Hampel',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90935629,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.2,
                          fractional: '1/5',
                          id: 1938001298,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517181496,
                      name: 'Naoki Tajima',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90935629,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 4,
                          fractional: '3/1',
                          id: 1938001698,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Tennis Game Winner',
                  typeRef: 'TGW',
                  version: 0,
                },
                MWIN: {
                  active: true,
                  handicap: 0,
                  name: 'Match Winner',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517179068,
                      name: 'Lenny Hampel',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90925420,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.12,
                          fractional: '1/8',
                          id: 1938001293,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517179069,
                      name: 'Naoki Tajima',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90925420,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 5,
                          fractional: '4/1',
                          id: 1938001486,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Match Winner',
                  typeRef: 'MWIN',
                  version: 0,
                },
              },
              toDisplaySelection: {
                WWPGS: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                TGW: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                MWIN: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
            {
              id: 5920662,
              name: 'Facundo Diaz-Acosta v Nicolas Moreno-de-Alboran',
              scheduledStart: 1611831000000,
              toDisplayMarket: {
                TGW: {
                  active: true,
                  handicap: 0,
                  name: 'Who will win game 7 of set 1?',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517202428,
                      name: 'Facundo Diaz-Acosta',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90935315,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 3.75,
                          fractional: '11/4',
                          id: 1938001522,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517202429,
                      name: 'Nicolas Moreno-de-Alboran',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90935315,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.2,
                          fractional: '1/5',
                          id: 1938001523,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Tennis Game Winner',
                  typeRef: 'TGW',
                  version: 0,
                },
                WWPGS: {
                  active: true,
                  handicap: 0,
                  name: 'Point Winner - Point 3, Game 7, Set 1',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517198031,
                      name: 'Facundo Diaz-Acosta',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90935628,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 2.2,
                          fractional: '6/5',
                          id: 1938000985,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517198032,
                      name: 'Nicolas Moreno-de-Alboran',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90935628,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.6,
                          fractional: '6/10',
                          id: 1938000986,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Who wins point in game of set',
                  typeRef: 'WWPGS',
                  version: 0,
                },
                MWIN: {
                  active: true,
                  handicap: 0,
                  name: 'Match Winner',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517197488,
                      name: 'Facundo Diaz-Acosta',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90929717,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.8,
                          fractional: '4/5',
                          id: 1938000975,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517197489,
                      name: 'Nicolas Moreno-de-Alboran',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90929717,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.9,
                          fractional: '9/10',
                          id: 1938001518,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Match Winner',
                  typeRef: 'MWIN',
                  version: 0,
                },
              },
              toDisplaySelection: {
                TGW: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                WWPGS: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                MWIN: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
            {
              id: 5920659,
              name: 'Rimpei Kawakami v Jumpei Yamasaki',
              scheduledStart: 1611828000000,
              toDisplayMarket: {
                MWIN: {
                  active: true,
                  handicap: 0,
                  name: 'Match Winner',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517162316,
                      name: 'Rimpei Kawakami',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90921483,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 4,
                          fractional: '3/1',
                          id: 1938001952,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517162317,
                      name: 'Jumpei Yamasaki',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90921483,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.2,
                          fractional: '1/5',
                          id: 1938001953,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Match Winner',
                  typeRef: 'MWIN',
                  version: 0,
                },
                TGW: {
                  active: true,
                  handicap: 0,
                  name: 'Who will win game 3 of set 2?',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517166332,
                      name: 'Rimpei Kawakami',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90935330,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.75,
                          fractional: '3/4',
                          id: 1938001959,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517166333,
                      name: 'Jumpei Yamasaki',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90935330,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.95,
                          fractional: '19/20',
                          id: 1938001960,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Tennis Game Winner',
                  typeRef: 'TGW',
                  version: 0,
                },
                WWPGS: {
                  active: true,
                  handicap: 0,
                  name: 'Point Winner - Point 3, Game 3, Set 2',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517164638,
                      name: 'Rimpei Kawakami',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90935647,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.6,
                          fractional: '6/10',
                          id: 1938001961,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517164639,
                      name: 'Jumpei Yamasaki',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90935647,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 2.2,
                          fractional: '6/5',
                          id: 1938001962,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Who wins point in game of set',
                  typeRef: 'WWPGS',
                  version: 0,
                },
              },
              toDisplaySelection: {
                MWIN: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                TGW: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                WWPGS: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
          ],
          featured: false,
          id: 4771,
          name: 'ITF Egypt F4',
          ref: 'ITF_EGYPT_F4',
        },
        {
          event: [
            {
              id: 5920645,
              name: 'Falkner Z / Lovric P v Lemoine Q / Mujan G',
              scheduledStart: 1611831600000,
              toDisplayMarket: {
                MWIN: {
                  active: true,
                  handicap: 0,
                  name: 'Match Winner',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517207831,
                      name: 'Falkner Z / Lovric P',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90931996,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.4,
                          fractional: '2/5',
                          id: 1938000907,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517207832,
                      name: 'Lemoine Q / Mujan G',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90931996,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 2.6,
                          fractional: '8/5',
                          id: 1938001572,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Match Winner',
                  typeRef: 'MWIN',
                  version: 0,
                },
                TGW: {
                  active: true,
                  handicap: 0,
                  name: 'Who will win game 4 of set 1?',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517207841,
                      name: 'Falkner Z / Lovric P',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90935132,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.05,
                          fractional: '1/20',
                          id: 1938001575,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517207842,
                      name: 'Lemoine Q / Mujan G',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90935132,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 7,
                          fractional: '6/1',
                          id: 1938001576,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Tennis Game Winner',
                  typeRef: 'TGW',
                  version: 0,
                },
                WWPGS: {
                  active: true,
                  handicap: 0,
                  name: 'Point Winner - Point 1, Game 5, Set 1',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517207843,
                      name: 'Falkner Z / Lovric P',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90935627,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 2,
                          fractional: 'Evs',
                          id: 1938000229,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517207844,
                      name: 'Lemoine Q / Mujan G',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90935627,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.7,
                          fractional: '7/10',
                          id: 1938000230,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Who wins point in game of set',
                  typeRef: 'WWPGS',
                  version: 0,
                },
              },
              toDisplaySelection: {
                MWIN: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                TGW: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                WWPGS: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
            {
              id: 5920661,
              name: 'Nicole Fossa-Huergo v Anastasia Nefedova',
              scheduledStart: 1611827100000,
              toDisplayMarket: {
                TGW: {
                  active: true,
                  handicap: 0,
                  name: 'Who will win game 8 of set 2?',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517148451,
                      name: 'Nicole Fossa-Huergo',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90935117,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.08,
                          fractional: '1/12',
                          id: 1938001568,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: true,
                      displayed: true,
                      id: 517148452,
                      name: 'Anastasia Nefedova',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90935117,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 6,
                          fractional: '5/1',
                          id: 1938001569,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Tennis Game Winner',
                  typeRef: 'TGW',
                  version: 0,
                },
                MWIN: {
                  active: true,
                  handicap: 0,
                  name: 'Match Winner',
                  selection: [
                    {
                      active: true,
                      displayed: true,
                      id: 517143291,
                      name: 'Nicole Fossa-Huergo',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90917649,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 11,
                          fractional: '10/1',
                          id: 1937946596,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: false,
                      displayed: true,
                      id: 517143292,
                      name: 'Anastasia Nefedova',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90917649,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.01,
                          fractional: '1/100',
                          id: 1937943100,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Match Winner',
                  typeRef: 'MWIN',
                  version: 0,
                },
                WWPGS: {
                  active: false,
                  handicap: 0,
                  name: 'Point Winner - Point 4, Game 8, Set 2',
                  selection: [
                    {
                      active: false,
                      displayed: true,
                      id: 517148453,
                      name: 'Nicole Fossa-Huergo',
                      nonRunner: false,
                      ordinal: 4,
                      price: [
                        {
                          bookId: 90935329,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 2.1,
                          fractional: '11/10',
                          id: 1937998680,
                        },
                      ],
                      typeRef: 'HOME',
                      version: 0,
                    },
                    {
                      active: false,
                      displayed: true,
                      id: 517148454,
                      name: 'Anastasia Nefedova',
                      nonRunner: false,
                      ordinal: 5,
                      price: [
                        {
                          bookId: 90935329,
                          bookType: 'PRICE',
                          current: true,
                          decimal: 1.65,
                          fractional: '13/20',
                          id: 1937998681,
                        },
                      ],
                      typeRef: 'AWAY',
                      version: 0,
                    },
                  ],
                  typeName: 'Who wins point in game of set',
                  typeRef: 'WWPGS',
                  version: 0,
                },
              },
              toDisplaySelection: {
                TGW: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                MWIN: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
                WWPGS: [
                  { typeRef: 'HOME', name: 'HOME' },
                  { typeRef: 'AWAY', name: 'AWAY' },
                ],
              },
            },
          ],
          featured: false,
          id: 4907,
          name: 'ITF Egypt 04A',
          ref: 'ITF_EGYPT_04A',
        },
      ],
      events_count: 2,
    },
    marketDropdown: {
      MWIN: 'Match Winner',
      TGW: 'Tennis Game Winner',
      WWPGS: 'Who wins point in game of set',
    },
    selectedmarketDropdown: ['MWIN', 'TGW'],
  },
};
