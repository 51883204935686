import styled from 'styled-components';
import {
  H,
  D,
  S,
  C,
  E,
  MB,
  MStyles,
  ModalButtonClose,
  EYE,
  Image,
  L,
  J,
} from 'CORE__UI/apps/LoginApp/core__loginModal';
import { BRAND, GREYS } from '../../globals/colours';
const MStyles_GDWN = {
  modal: {
    ...MStyles.modal,
    backgroundColor: `${GREYS.silverD}`,
  },
};

const H_GDWN = styled(H)`
  color: ${GREYS.white};
`;

const S_GDWN = styled(S)`
  color: ${GREYS.doveD};
  background-color: ${GREYS.silverD};
`;
const D_GDWN = styled(D)`
  background-color: ${BRAND.primary};
`;
const C_GDWN = styled(C)`
  color: ${GREYS.doveD};
  a {
    color: ${GREYS.white};
  }
  img {
    border-radius: 50%;
  }
  p {
    margin-bottom: 12px;
  }
`;
const ModalButtonClose_Copy = styled(ModalButtonClose)`
  background-color: inherit;
  border: 1px solid ${GREYS.silver};
  color: ${GREYS.white};
`;
export {
  H_GDWN as H,
  D_GDWN as D,
  S_GDWN as S,
  C_GDWN as C,
  E,
  MB,
  MStyles_GDWN as MStyles,
  ModalButtonClose_Copy as ModalButtonClose,
  EYE,
  L,
  J,
  Image,
};
