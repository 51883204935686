export const customerData = {
  accessToken: '21fb70a2-65b9-4863-964d-624a11eb8389',
  accountStatus: 'ACTIVE',
  balance: 892,
  currencyCode: 'GBP',
  firstActivated: 1610542339149,
  firstName: 'sudheer',
  id: 5066,
  lastName: 'ponnala',
  level: 'LEVEL_ZERO',
  title: 'Dr',
  userName: 'sudheerp',
  account: '/my-account/',
};

export const customerDataNoBalance = {
  accessToken: '21fb70a2-65b9-4863-964d-624a11eb8389',
  accountStatus: 'ACTIVE',
  currencyCode: 'GBP',
  firstActivated: 1610542339149,
  firstName: 'sudheer',
  id: 5066,
  lastName: 'ponnala',
  level: 'LEVEL_ZERO',
  title: 'Dr',
  userName: 'sudheerp',
};

export const response = {
  data: {
    response: {
      customer: {},
    },
  },
};
