import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { GREYS } from 'UI/globals/colours';
import { MC as MC_LOCAL } from 'UI/globals/ModalContent';
import { getComponent } from 'Services/core__imports';

//all are common properties, not to be imported from local
const MC = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s;
  z-index: 9999999;
  ${({ showModal }) =>
    showModal
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}
`;
const MO = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
//hard coded #fff as this needs to be overwritten by styles passed
const MI = styled.div`
  position: relative;
  ${({ theme }) =>
    theme.dark
      ? css`
          background-color: ${GREYS.silverD};
        `
      : css`
          background-color: ${GREYS.white};
        `}

  ${({ style }) =>
    style?.backgroundColor &&
    css`
      background-color: ${style.backgroundColor};
    `}
`;
const CLS = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
`;
const MODAL_CONTENT = getComponent(MC_LOCAL, MC);
//caution : do not make local file import this is core functionality, pass extra params to overwrite css
export const Modal = ({
  open,
  children,
  overlayStyle,
  styles,
  onClose,
  containerStyle,
  closeIcon,
  cantEscape,
  regSuccessPopup,
}) => {
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    setShowModal(open);
  }, [open]);
  const close = () => {
    !cantEscape && onClose();
  };
  return (
    <MODAL_CONTENT showModal={showModal} style={containerStyle}>
      <MO onClick={close} style={overlayStyle} />
      <MI style={styles.modal} regSuccessPopup={regSuccessPopup}>
        {children}
        <CLS className="close-icon" onClick={close}>
          {closeIcon}
        </CLS>
      </MI>
    </MODAL_CONTENT>
  );
};

Modal.propTypes = {
  children: PropTypes.any,
  closeIcon: PropTypes.any,
  containerStyle: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.any,
  overlayStyle: PropTypes.any,
  styles: PropTypes.shape({
    modal: PropTypes.any,
  }),
  cantEscape: PropTypes.bool,
  regSuccessPopup: PropTypes.bool,
};
