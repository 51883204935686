import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { getComponent } from 'Services/core__imports';
import theme from 'UI/globals/theme';
import { SB, SecB, NeutralB, I as I_LOCAL } from 'UI/buttons/ModalButtonStyles';
import I_CORE from 'UI/globals/Icons';

const I = getComponent(I_LOCAL, I_CORE);

const styleAs = rank => {
  switch (rank) {
    case 'secondary':
      return SecB;
    case 'neutral':
      return NeutralB;
    default:
      return;
  }
};

const ModalButton = ({
  label,
  rank,
  onClick,
  noanimation,
  iconName,
  iconMarginR,
  showJNModal,
  bbFlag,
  betBooster,
  disabled,
  ...props
}) => {
  return (
    <ThemeProvider theme={theme}>
      <SB
        showJNModal={showJNModal}
        bbFlag={bbFlag}
        as={styleAs(rank)}
        noanimation={noanimation}
        onClick={onClick && onClick}
        name={label}
        betBooster={betBooster}
        disabled={disabled}
        {...props}
      >
        {iconName && (
          <I
            betBooster={betBooster}
            iconName={iconName}
            marginR={iconMarginR}
          />
        )}
        {label}
      </SB>
    </ThemeProvider>
  );
};

ModalButton.propTypes = {
  label: PropTypes.node.isRequired,
  rank: PropTypes.oneOf(['secondary', 'neutral', 'null']),
  onClick: PropTypes.func,
  noanimation: PropTypes.bool,
  bbFlag: PropTypes.bool,
  iconName: PropTypes.string,
  iconMarginR: PropTypes.number,
  showJNModal: PropTypes.bool,
  name: PropTypes.string,
  betBooster: PropTypes.bool,
  disabled: PropTypes.bool,
};

ModalButton.defaultProps = {
  label: 'Continue',
  noanimation: false,
  iconName: '',
  iconMarginR: 0,
};

export default ModalButton;
