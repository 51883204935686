import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'CORE__UI/globals/Modal/core__modal'; //caution: don't use local import
import { MStyles } from 'UI/apps/LoginApp/LoginModal';
import { createCookie } from 'Services/cookie/core__cookies';
import {
  BH as BH_LOCAL,
  BN as BN_LOCAL,
  I as I_LOCAL,
  B as B_LOCAL,
  LL as LL_LOCAL,
} from 'UI/apps/AuthorizationHeader/SuccessfulLoginPopup';
import {
  BH as BH_GLOBAL,
  BN as BN_GLOBAL,
  I as I_GLOBAL,
  B as B_GLOBAL,
  LL as LL_GLOBAL,
} from 'CORE__UI/apps/AuthorizationHeader/core__success-login-popup';
import { Translations } from '../../../core__authorization-header-app';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import { appConfig } from '../../../core__appconfig.stub';
import { GLOBAL_PATH } from 'Services/global/core__constants';
const BH = BH_LOCAL || BH_GLOBAL;
const BN = BN_LOCAL || BN_GLOBAL;
const I = I_LOCAL || I_GLOBAL;
const B = B_LOCAL || B_GLOBAL;
const LL = LL_LOCAL || LL_GLOBAL;

export const SuccessLoginPopup = ({
  loginSuccessModal,
  setLoginSuccessModal,
  includeLimitsLink,
  successType,
  additionalInfoLimitsHtml,
  gameLimit,
}) => {
  const loginSuccess = gameLimit => {
    setLoginSuccessModal(false);
    createCookie(appConfig.LOGIN_ACK, true);
    const session = FSBCustomerSession.getSession();
    PubSub.emit(PubsubEvents.OPEN_ID_COMPLY, session);
    if (successType === 'register' && !gameLimit) {
      window.location.href = GLOBAL_PATH.DEPOSIT_NOW;
    }
  };

  return (
    <Modal cantEscape={true} open={loginSuccessModal} styles={MStyles}>
      <BH>
        <BN>{Translations.get(`${successType}.successful`)}</BN>
      </BH>
      <I>{Translations.get(`${successType}.additional.info`)}</I>
      {includeLimitsLink && (
        <LL>
          <I removePadding={true}>
            {Translations.get(`${successType}.additional.info.limits`)}
            {''}

            {successType === 'register' && (
              <a onClick={() => loginSuccess('gameLimit')} href={gameLimit}>
                {Translations.get('text.here')}
              </a>
            )}
            {successType === 'login' && additionalInfoLimitsHtml && (
              <div
                dangerouslySetInnerHTML={{ __html: additionalInfoLimitsHtml }}
              ></div>
            )}
          </I>
        </LL>
      )}
      <br />
      <B onClick={() => loginSuccess()}>
        {Translations.get(`${successType}.ok`)}
      </B>
    </Modal>
  );
};
SuccessLoginPopup.propTypes = {
  setLoginSuccessModal: PropTypes.func,
  loginSuccessModal: PropTypes.bool,
  includeLimitsLink: PropTypes.bool,
  successType: PropTypes.string,
  additionalInfoLimitsHtml: PropTypes.string,
  gameLimit: PropTypes.string,
};
