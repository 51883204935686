export default {
  LOGIN: 'login', //customerId
  LOGIN_FAILED: 'login_failed',
  LOGIN_SUCCESS: 'login_success',
  LOGIN_STARTED: 'login_started',
  LOGIN_SUBMITTED: 'login_submitted',
  LOGOUT_SUCCESS: 'logout_success',

  BONUS_BET_REJECTED: 'bonus_bet_rejected',
  BONUS_TOO_LOW: 'bonus_too_low',

  REGISTRATION_STARTED: 'register_start', //
  REGISTRATION_INTERACTION: 'register_interaction',
  REGISTRATION_STEP_COMPLETED: 'register_step_completed',
  REGISTRATION_SUBMITTED: 'register_submitted',
  REGISTRATION_FAILED: 'register_failed',
  REGISTRATION_COMPLETED: 'register', //customerId, email, balance, currencyCode
  REGISTRATION_VERIFICATION: 'register_verification',

  BET_ADD_NEW: 'bet-add-new',
  BET_OPEN: 'bet_open',
  BET_SUBMITTED: 'bet_submitted',
  BET_SUCCESS: 'bet_success',
  BET_FAILED: 'bet_failed',

  DEPOSIT_COMPLETE: 'deposit_complete', //customerId, ammount
  FIRST_DEPOSIT: 'first_deposit', //currency, amount
  DEPOSIT_STARTED: 'deposit_started',
  DEPOSIT_SUBMITTED: 'deposit_submitted',
  DEPOSIT_SUCCESS: 'deposit_success',
  DEPOSIT_FAILED: 'deposit_failed',

  WITHDRAWAL_SUBMITTED: 'withdrawal_submitted',
  WITHDRAWAL_SUCCESS: 'withdrawal_success',
  WITHDRAWAL_FAILED: 'withdrawal_failed',

  SELFEXCLUSION: 'self-exclusion',
  BETCONFIRM: 'bet-confirm', //customerId, total stake
  COOKIE_CONSENT: 'cookie-consent',
};
