import {
  THW,
  LB,
  RB,
  RBW,
  RBM,
  EC,
  E,
  B,
  EX,
  BS,
  R,
  RS,
  MB,
  BC,
  TCTP,
  CT,
  BETCOUNTER,
  UBW,
  UAI,
  ACCOUNTBALANCECOUNTER,
} from 'CORE__UI/apps/AuthorizationHeader/core__authHeader';

import styled, { css } from 'styled-components';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';
import { GREYS, BRAND, FUNCTIONAL } from 'UI/globals/colours';

const RB_EX = styled(RB)`
  @media (max-width: ${BREAKPOINT_S}) {
    background-color: ${BRAND.secondary};
    color: ${GREYS.black};
    font-weight: 800;
    span span {
      min-width: 64px;
      text-align: center;
    }
    @media (hover: hover) {
      &:hover {
        background-color: ${BRAND.secondaryD};
        color: ${GREYS.black};
      }
    }
    ${({ hide }) =>
      hide &&
      css`
        display: none;
      `}
  }
`;

const LB_EX = styled(LB)`
  margin-right: 0px;
  //margin for icon
  span {
    margin-left: 0;
  }
  div {
    font-size: 12px;
  }
  margin-right: 0px;
  width: unset;
  flex-direction: column;
  gap: 2px;
  @media (hover) {
    &:hover {
      border: 0;
      color: ${BRAND.secondary};
    }
  }
  @media (max-width: ${BREAKPOINT_S}) {
    width: auto;
    padding: 0 0.5em;
  }
`;

const BETCOUNTER_EX = styled(BETCOUNTER)`
  color: ${({ unsettledBetsCount }) =>
    unsettledBetsCount > 0 ? GREYS.doveL : FUNCTIONAL.guideD};
  background: ${({ unsettledBetsCount }) =>
    unsettledBetsCount > 0 ? BRAND.highlight : BRAND.tertiaryL};
  margin-left: 0px !important;
`;
const THW_EX = styled(THW)`
  column-gap: 35px;
  @media (max-width: ${BREAKPOINT_S}) {
    margin-top: 6px;
    column-gap: 0px;
  }
`;

const UBW_EX = styled(UBW)``;

const UAI_EX = styled(UAI)`
  display: flex;
  justify-content: center;
  gap: 2px;
  flex-direction: column;
  align-items: center;
`;

const ACCOUNTBALANCECOUNTER_EX = styled(ACCOUNTBALANCECOUNTER)`
  margin-left: 0px;
  width: unset;
  margin-left: 0px !important;
`;

export {
  THW_EX as THW,
  LB_EX as LB,
  RB_EX as RB,
  RBW,
  RBM,
  EC,
  E,
  B,
  EX,
  BS,
  R,
  RS,
  MB,
  BC,
  TCTP,
  CT,
  BETCOUNTER_EX as BETCOUNTER,
  UBW_EX as UBW,
  UAI_EX as UAI,
  ACCOUNTBALANCECOUNTER_EX as ACCOUNTBALANCECOUNTER,
};
