import { BREAKPOINT_S } from 'UI/globals/breakpoints';
import styled, { css } from 'styled-components';
import { GREYS, FONT, SHADOW } from 'UI/globals/colours';

export const SC = styled.div`
  max-width: 768px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${BREAKPOINT_S}) {
    overflow-x: scroll;
    display: -webkit-box;
    white-space: nowrap;
  }
  height: 64px;
  color: ${props => (props.theme.dark ? GREYS.white : GREYS.black)};
`;

export const AH = styled.ul`
  background-color: ${props =>
    props.theme.dark ? GREYS.silverD : GREYS.white};
  box-shadow: ${SHADOW};
  color: ${props => (props.theme.dark ? GREYS.white : GREYS.black)};
`;

export const AV = styled.li`
  margin: 0 4px;
  line-height: 3;
  cursor: pointer;
  &:hover {
    color: ${FONT.primary};
    font-weight: 800;
    border-bottom: 1px solid ${FONT.primary};
  }

  &:before {
    display: block;
    content: attr(title);
    font-weight: 800;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  ${({ active }) =>
    active &&
    css`
      color: ${FONT.primary};
      border-bottom: 1px solid ${FONT.primary};
      font-weight: bold;
    `}
`;

export const A = styled.a`
  border-right: 1px solid ${GREYS.doveD};
  padding: 0 4px;
  color: ${FONT.dark};
  font-size: 20px;
  padding-right: 16px;
  margin-right: 16px;
  cursor: pointer;
`;

export const SCC = styled(SC)`
  color: ${GREYS.white};
  display: flex;
  justify-content: center;
  align-content: center;
`;
