import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import getComposeEnhancers from 'Services/redux/core__redux-dev-tools';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { clientURL } from '../AccountApp/core__AccountInitialState';
import { getRequest } from 'Services/http/core__axios';

export const dataReducer = (state = [], action) => {
  switch (action.type) {
    //Storing customer data in redux store
    case 'CUSTOMER_DATA': {
      return {
        ...state,
        customerData: action.data,
      };
    }

    case 'CUSTOMER_CARD': {
      return {
        ...state,
        customerCard: action.data.customer.card,
      };
    }

    case 'LOGOUT_POPUP': {
      return {
        ...state,
        logoutPopup: action.payload,
      };
    }
    case 'SHOW_BALANCE': {
      return {
        ...state,
        show: action.payload,
      };
    }
    case 'SHOW_BET': {
      return {
        ...state,
        showBet: action.payload,
      };
    }
    case 'BET_COUNT': {
      return {
        ...state,
        betCount: action.payload,
      };
    }
    case 'CUSTOMER_BALANCE': {
      return {
        ...state,
        customerBalance: action.balance,
      };
    }
    default:
      return state;
  }
};

//Function to get the url for free bet
export function getCustomerUrl() {
  const base = `${clientURL.fsb}/customer`;
  const url = `${base}.fsb`;
  return url;
}
//function to fetch customer details
/**
 * @param {*} carouselSearchBar fucntion call in initial load
 * @param {*} showLoggedInTimer parameter to get user prev session created timestamp
 * */

//TODO - REVIEW
export const old_getUserDetails = (token, showLoggedInTimer) => dispatch => {
  const url = getCustomerUrl();
  const fetchUrl = `${url}?access_token=${token}&productGroupRef=SPORTSBOOK,CASINO&details=true&metadata=true&platformConfig=true${
    (showLoggedInTimer && '&prevSessionCreated=true') || ''
  }`;

  if (!token) return;

  const requester = getRequest;
  return requester(fetchUrl).then(res => {
    const data = res.customer;
    //publish event to LastLoggedIn App to show user last logged in details
    if (showLoggedInTimer) {
      PubSub.emit(PubsubEvents.LAST_LOGGED_IN, data);
    }
    dispatch({ type: 'CUSTOMER_DATA', data });
  });
};

export const getUserVerification = token => dispatch => {
  const fetchUrl = `${clientURL.fsb}/kycDocument/idocufy?access_token=${token}`;
  const requester = getRequest;
  return requester(fetchUrl).then(res => {
    const data = res.customer;
    //publish event to LastLoggedIn App to show user last logged in details
    dispatch({ type: 'CUSTOMER_VERIFICATION', data });
  });
};

export const getUserDetails = (token, showLoggedInTimer, data) => dispatch => {
  if (showLoggedInTimer) {
    const url = getCustomerUrl();
    const fetchUrl = `${url}?access_token=${token}&productGroupRef=SPORTSBOOK,CASINO&details=true&metadata=true&platformConfig=true${
      (showLoggedInTimer && '&prevSessionCreated=true') || ''
    }`;
    const requester = getRequest;
    return requester(fetchUrl).then(res => {
      const data = res.customer;
      //publish event to LastLoggedIn App to show user last logged in details
      if (showLoggedInTimer) {
        PubSub.emit(PubsubEvents.LAST_LOGGED_IN, data);
      }
      dispatch({ type: 'CUSTOMER_DATA', data });
    });
  } else {
    if (showLoggedInTimer) {
      PubSub.emit(PubsubEvents.LAST_LOGGED_IN, data);
    }
    dispatch({ type: 'CUSTOMER_DATA', data });
  }
};

export const getCustomerCard = token => dispatch => {
  const url = `${clientURL.fsb}/card.fsb`;
  const fetchUrl = `${url}?access_token=${token}`;

  if (!token) return;

  const requester = getRequest;
  return requester(fetchUrl).then(res => {
    const data = res;
    dispatch({ type: 'CUSTOMER_CARD', data });
  });
};

//Combine reducers into a single reducer
export const reducer = combineReducers({
  data: dataReducer,
});

//Storing the Customer details
export const loginData = (data, showLoggedInTimer) => dispatch => {
  if (data?.accessToken) {
    if (showLoggedInTimer) {
      getUserDetails(data.accessToken, showLoggedInTimer);
    } else {
      dispatch({ type: 'CUSTOMER_DATA', data });
    }
  }
};

//function to show hide the balance
export const showBalance = show => dispatch => {
  let showValue = show;
  if (show) {
    showValue = false;
  } else {
    showValue = true;
  }
  dispatch({
    type: 'SHOW_BALANCE',
    payload: showValue,
  });
};

//function to show my bet on the header
export const showMyBet = bet => dispatch => {
  dispatch({
    type: 'SHOW_BET',
    payload: bet,
  });
};

//function to set mybet count
export const showMyBetCount = count => dispatch => {
  dispatch({
    type: 'BET_COUNT',
    payload: count,
  });
};

//open logout popup
export const openLogout = (data, logout, orisisAuth) => dispatch => {
  dispatch({ type: 'LOGOUT_POPUP', payload: data });
  if (logout) {
    PubSub.emit(PubsubEvents.SESSION_DESTROY);
    FSBCustomerSession.deleteSession();

    //osiros exit
    try {
      if (window.osiros) {
        window.osiros.exit();
        localStorage.removeItem('gameRegistered');
      }
    } catch (error) {
      //no handling required
    }

    if (orisisAuth) {
      window.location.href = '/welcome/';
    } else {
      window.location.href = '/sportsbook/';
    }
  }
};

//function to update account balance
export const updateAccountBalance = balance => dispatch => {
  dispatch({ type: 'CUSTOMER_BALANCE', balance });
};

export const setCustomerData = data => dispatch => {
  dispatch({ type: 'CUSTOMER_DATA', data });
};

const composeEnhancers = getComposeEnhancers({
  name: 'authorization-header-store',
});

export default initialState =>
  createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
