import {
  SC,
  AH,
  AV,
  A,
} from 'CORE__UI/apps/AccountApp/AccountHeader/core__accountHeader';
import styled, { css } from 'styled-components';
import { GREYS, FONT, BRAND } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

const AH_GDWN = styled(AH)`
  background-color: ${GREYS.silverD};
  color: ${GREYS.white};
  box-shadow: none;
`;

const SC_EX = styled(SC)`
  color: ${GREYS.white};
`;

const A_EX = styled(A)`
  color: ${FONT.dark};
`;
const AV_EX = styled(AV)`
  @media (max-width: ${BREAKPOINT_S}) {
    margin: 0 24px;
  }
  @media (hover) {
    &:hover {
      color: ${GREYS.white};
      border-bottom: 2px solid ${BRAND.secondary};
    }
  }
  ${({ active }) =>
    active &&
    css`
      color: ${GREYS.white};
      border-bottom: 2px solid ${BRAND.secondary};
    `}
`;

export { SC_EX as SC, AH_GDWN as AH, A_EX as A, AV_EX as AV };
