import React from 'react';
import PropTypes from 'prop-types';
import { CP, L, C, DC, TT } from 'UI/apps/AuthorizationHeader/AuthHeader';
import { Translations } from '../../../core__authorization-header-app';

const PepPopup = ({ closePopup }) => {
  return (
    <CP>
      <DC>
        <TT>{Translations.get('text.peppopup.title')}</TT>
        <C onClick={closePopup}>&#10005;</C>
      </DC>
      <TT>{Translations.get('text.peppopup.content')}</TT>
      <L onClick={closePopup}>{Translations.get('text.success.close')}</L>
    </CP>
  );
};

PepPopup.propTypes = {
  closePopup: PropTypes.func,
};

export default PepPopup;
