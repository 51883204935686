// if you want to change the GTM event names for the whole platform / all brands
// then edit this file

// if you want to change event names for only one brand / client
// then create a key in project.js called "customGtmEventNames" with all the overrides

export default {
  REGISTER_START: 'register_start',
  REGISTER_INTERACTION: 'register_interaction',
  REGISTER_STEP_COMPLETED: 'register_step_completed',
  REGISTER_SUBMITTED: 'register_submitted',
  REGISTER_FAILED: 'register_failed',
  REGISTER_COMPLETED: 'register',
  REGISTER_VERIFICATION: 'register_verification',

  LOGIN_STARTED: 'login_started',
  LOGIN_SUBMITTED: 'login_submitted',
  LOGIN_FAILED: 'login_failed',
  LOGIN_SUCCESS: 'login_success',

  BET_ADD_NEW: 'bet_add_new',
  BET_OPEN: 'bet_open',
  BET_SUBMITTED: 'bet_submitted',
  BET_SUCCESS: 'bet_success',
  BET_FAILED: 'bet_failed',

  DEPOSIT_STARTED: 'deposit_started',
  DEPOSIT_SUBMITTED: 'deposit_submitted',
  DEPOSIT_SUCCESS: 'deposit_success',
  DEPOSIT_FAILED: 'deposit_failed',

  WITHDRAWAL_STARTED: 'withdrawal_started',
  WITHDRAWAL_SUBMITTED: 'withdrawal_submitted',
  WITHDRAWAL_SUCCESS: 'withdrawal_success',
  WITHDRAWAL_FAILED: 'withdrawal_failed',

  WELCOME_POP_UP_CROSS: 'welcome-pop-up-cross',
  WELCOME_POP_UP_IMAGE_CLICK: 'welcome-pop-up-image-click',
  WELCOME_POP_UP_DEPOSIT_CLAIM: 'welcome-pop-up-deposit-claim',
};
