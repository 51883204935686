import styled, { css } from 'styled-components';
import { BRAND, GREYS, FONT } from 'UI/globals/colours';
import theme from 'UI/globals/theme';

export const SB = styled.button`
  display: flex;
  flex: 1;
  max-width: 500px;
  width: 100%;
  height: 40px;
  width: 100%;
  ${({ narrow }) =>
    narrow &&
    css`
      width: auto;
      padding: 0 60px;
      flex: 0 auto;
    `};
  ${({ small }) =>
    small &&
    css`
      height: 32px;
      padding: 0 10px;
    `};
  justify-content: center;
  align-items: center;
  border: ${({ disabled }) =>
    disabled ? `1px solid ${FONT.dark}` : `1px solid ${BRAND.secondary}`};
  border-radius: 4px;
  font-family: ${theme.fontFamily};
  font-size: 14px;
  font-weight: 800;
  text-transform: uppercase;
  color: ${GREYS.black};
  background-color: ${BRAND.secondary};
  cursor: pointer;
  ${({ noanimation }) => {
    if (!noanimation) {
      return css`
        transition: all 250ms ease;
      `;
    }
  }}
  &:active {
    background-color: ${({ disabled }) => !disabled && BRAND.secondaryD};
    outline: none;
  }
  ${({ theme }) =>
    theme.dark &&
    css`
      background-color: ${({ disabled }) =>
        disabled ? 'inherit' : BRAND.favourite};
      color: ${({ disabled }) => (disabled ? FONT.dark : GREYS.black)};
    `}

  ${({ mb }) =>
    mb &&
    css`
      margin-bottom: ${mb};
    `}
    @media (hover: none) {
    background-color: ${({ disabled }) => !disabled && BRAND.secondaryD};
    outline: none;
  }
`;

export const SecB = styled(SB)`
  background-color: ${props =>
    props.themes === 'dark' ? GREYS.silverD : GREYS.white};
  color: ${props => (props.themes === 'dark' ? GREYS.black : BRAND.primary)};
  border: 1px solid ${BRAND.primary};
  &:active {
    color: ${GREYS.white};
    background-color: ${BRAND.primary};
  }
  ${({ theme }) =>
    theme.dark &&
    css`
      background-color: ${GREYS.silverD};
    `}
  @media (hover: none) {
    color: ${GREYS.white};
    background-color: ${BRAND.primary};
  }
`;

export const NeutralB = styled(SecB)`
  color: ${GREYS.silver};
  border-color: ${GREYS.silver};
  &:active,
  &:focus {
    background-color: ${GREYS.white};
    color: ${BRAND.primaryD};
    border-color: ${BRAND.primaryD};
  }
  @media (hover: none) {
    background-color: ${GREYS.white};
    color: ${BRAND.primaryD};
    border-color: ${BRAND.primaryD};
  }
`;
