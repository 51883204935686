import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import getComposeEnhancers from 'Services/redux/core__redux-dev-tools';
import { postRequest, getRequest } from 'Services/http/core__axios';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import project from '../../../project';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { getCookie, createCookie } from 'Services/cookie/core__cookies';
import {
  emailVarify,
  checkValidMobileNumber,
} from 'Services/global/core__check-validation';
import TRACKING_CONSTANTS from 'Services/constants/core__tracking';
import addCustomGTMEventToDataLayer, {
  gtmEventNames,
} from 'Services/gtm/core__addCustomGTMEventToDataLayer';
import adjustTrackEvent, {
  eventTokens,
} from 'Services/adjust/core__adjust-web-sdk';
import { isAndroidApp } from 'Services/globalfunctions/core__global-functions';

export let handleLoginSuccessListener;

const initialState = {
  showModal: false,
  loginObject: {
    userName: {
      value: '',
      rule: 'required',
      error: null,
    },
    passWord: {
      value: '',
      rule: 'required',
      error: null,
    },
    meta: {
      isValid: false,
      error: null,
    },
    errorMessage: '',
    returnCode: '',
    fromBetslip: '',
    registrationSuccess: false,
    resetSuccess: false,
    isLoginEnable: true,
  },
};

export const modalReducer = (state = [], action) => {
  switch (action.type) {
    case 'LOGIN_MODAL_DATA':
      return action.openModal;
    default:
      return state;
  }
};

export const loginReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOGIN_USERNAME':
      return {
        ...state,
        userName: { value: action.userName, rule: 'required', error: null },
      };
    case 'LOGIN_PASSWORD':
      return {
        ...state,
        passWord: { value: action.passWord, rule: 'required', error: null },
      };
    case 'LOGIN_MFA_CODE':
      return {
        ...state,
        mfaCode: { value: action.mfaCode, rule: 'required', error: null },
      };
    case 'SET_ERROR_MESSAGE':
      return { ...state, errorMessage: action.errorMessage };
    case 'SET_RETURN_CODE':
      return { ...state, returnCode: action.returnCode };
    case 'LOGIN_EMIT_FROM':
      return { ...state, fromBetslip: action.fromBetslip };
    case 'SET_LOGIN_OBJECT':
      return { ...state, loginObject: action.loginObject };
    case 'SET_REGISTRATION_SUCCESSS':
      return { ...state, registrationSuccess: action.registrationSuccess };
    case 'SET_RESET_SUCCESS':
      return { ...state, resetSuccess: action.resetSuccess };
    case 'IS_BUTTON_ENABLE':
      return { ...state, isLoginEnable: action.disableCheck };
    default:
      return state;
  }
};

export const reducer = combineReducers({
  showModal: modalReducer,
  loginObject: loginReducer,
});

export const selectModal = openModal => ({
  type: 'LOGIN_MODAL_DATA',
  openModal,
});

export const changeUserName = userName => ({
  type: 'LOGIN_USERNAME',
  userName,
});

export const changePassWord = passWord => ({
  type: 'LOGIN_PASSWORD',
  passWord,
});

export const changeMfaCode = mfaCode => ({
  type: 'LOGIN_MFA_CODE',
  mfaCode,
});

export const setErrorMessage = errorMessage => ({
  type: 'SET_ERROR_MESSAGE',
  errorMessage,
});

export const setReturnCode = returnCode => ({
  type: 'SET_RETURN_CODE',
  returnCode,
});

export const setLoginFrom = fromBetslip => ({
  type: 'LOGIN_EMIT_FROM',
  fromBetslip,
});

export const updateLoginObject = loginObject => ({
  type: 'SET_LOGIN_OBJECT',
  loginObject,
});

export const setRegistrationSuccess = registrationSuccess => ({
  type: 'SET_REGISTRATION_SUCCESSS',
  registrationSuccess,
});

export const setResetSuccess = resetSuccess => ({
  type: 'SET_RESET_SUCCESS',
  resetSuccess,
});

export const onRegisterModalClick = openModal => dispatch => {
  sessionStorage.removeItem('RegSuccess');
  dispatch(setRegistrationSuccess(openModal));
};

export const onResetModalClick = openModal => dispatch => {
  sessionStorage.removeItem('ResetSuccess');
  dispatch(setResetSuccess(openModal));
};

export const updateLoginEnable = disableCheck => ({
  type: 'IS_BUTTON_ENABLE',
  disableCheck,
});

/**
 * opens the Login modal
 * @param { boolean } openModal
 */
export const onModalClick = (
  openModal,
  txt,
  redirectToCasinoPage,
  popupmodalcheck
) => dispatch => {
  if (
    !openModal &&
    (window.location.pathname.includes('/my-account/') ||
      window.location.pathname.includes('/betgames/'))
  ) {
    if (redirectToCasinoPage) {
      window.location.href = '/casino/';
    } else {
      window.location.href = '/sportsbook/';
    }
  }
  if (!popupmodalcheck) {
    dispatch(onRegisterModalClick(false));
  }
  dispatch(selectModal(openModal));
  dispatch(setLoginFrom(txt));
  dispatch(changeUserName(''));
  dispatch(changePassWord(''));
  dispatch(changeMfaCode(''));
  dispatch(setErrorMessage(''));
  dispatch(setReturnCode(''));
};

/**
 * triggers on every form input change
 * @param { event } e
 * @param { string } text
 */
export const fieldChanged = (e, text) => dispatch => {
  switch (text) {
    case 'mfaCode':
      dispatch(changeMfaCode(e));
      break;
    case 'username':
      dispatch(changeUserName(e.target.value));
      break;
    case 'password':
      dispatch(changePassWord(e.target.value));
      break;

    default:
      break;
  }
  // Can not be set to blank because this determines if the mfaCode input appears
  if (text !== 'mfaCode') {
    dispatch(setErrorMessage(''));
    dispatch(setReturnCode(''));
  }
};

/**
 * Method to trigger on the login button
 * @param { object } loginObject
 */

export const callLogin = (
  loginObject,
  appConfig,
  opts = {},
  username,
  formatEmail
) => dispatch => {
  const {
    resendMfaCode,
    redirectToCasinoPage,
    registrationScreen,
    isHio,
    usernamePrefix = '',
    gameUrl,
    usernameValidationKey,
  } = opts;
  dispatch(updateLoginEnable(false));
  dispatch(checkValidations(loginObject, usernameValidationKey));
  if (loginObject.meta.isValid) {
    const url = `/fsb-api-rest/authenticate.fsb`;
    const userName = `${usernamePrefix}${loginObject.userName.value.trim()}`;
    //check if username is email
    // handle UUID from Cookies

    const reqObject = {
      clientId: appConfig.clientid ?? project.clientId,
      clientSecret: appConfig.clientsecret ?? project.clientSecret,
      password: encodeURIComponent(loginObject.passWord.value),
      uuid: getCookie('clid'),
    };

    if (emailVarify(userName)) {
      reqObject['email'] = formatEmail ? userName.toLowerCase() : userName;
    } else if (userName.includes('+') || checkValidMobileNumber(userName)) {
      reqObject['mobileNo'] = encodeURIComponent(userName);
    } else {
      reqObject['userName'] = userName;
    }
    if (!resendMfaCode && loginObject.mfaCode?.value) {
      reqObject.mfaCode = loginObject.mfaCode.value;
    }
    addCustomGTMEventToDataLayer({
      event: gtmEventNames.LOGIN_SUBMITTED,
    });
    return postRequest(url, reqObject).then(res => {
      if (res.inError) {
        addCustomGTMEventToDataLayer({
          event: gtmEventNames.LOGIN_FAILED,
          data: {
            message:
              res?.response?.status?.messageKey ||
              res?.response?.status?.value ||
              res?.response?.status?.devMessage,
          },
        });
        dispatch(updateLoginEnable(true));
        dispatch(setReturnCode(res.response.status.returnCode));
        //response !== 200
        if (
          res?.response?.status?.messageKey ===
          'api.error.account.error.account.pending_activation'
        ) {
          createCookie('secondStepRegistration', true);
          createCookie('username', loginObject.userName.value?.toLowerCase());
          createCookie('password', loginObject.passWord.value);
          window.location.href = '/register/';
        } else {
          const message =
            res?.response?.status?.value || res?.response?.status?.devMessage;
          if (message) {
            dispatch(setErrorMessage(message));
          }
        }
      } else if (isHio && res?.customer?.accessToken) {
        // handle hio Condition
        dispatch(
          handeHIORequiredCondition(
            res,
            loginObject,
            registrationScreen,
            redirectToCasinoPage
          )
        );
      } else {
        if (res && res?.customer && res?.customer?.accessToken) {
          dispatch(
            handleLoginSuccess(
              res,
              loginObject,
              registrationScreen,
              redirectToCasinoPage,
              gameUrl
            )
          );
        } else {
          dispatch(selectModal(false));
        }
      }
      //handle the success response here
    });
  }
  dispatch(updateLoginEnable(true));
};

/**
 * Method to check the validations on login button click
 * @param { string } field
 * @param { object } loginObject
 */
export const checkValidations = (
  loginObject,
  usernameValidationKey
) => dispatch => {
  const { userName, passWord } = loginObject;
  if (userName.value.trim() === '') {
    loginObject['userName'].error = usernameValidationKey
      ? usernameValidationKey
      : 'valid.username';
  }
  if (passWord.value.trim() === '') {
    loginObject['passWord'].error = 'valid.password';
  }
  if (userName.value.trim() && passWord.value.trim()) {
    loginObject.meta.isValid = true;
  } else {
    loginObject.meta.isValid = false;
  }
  dispatch(updateLoginObject(loginObject));
};

/**
 * opens the register modal
 */
export const callJoinNow = () => {
  window.location.href = '/register/';
};

/**
 * set the login modal width
 */
export const getModalWidth = BREAKPOINT_M => {
  try {
    return screen.width < BREAKPOINT_M.replace('px', '') ? '100%' : '480px';
  } catch (e) {
    return '100%';
  }
};

/**
 * function after success login stuffs
 * @param {object} res
 * @param {object} loginObject
 * @param {bool} loginObject
 * @param {bool} redirectToCasinoPage
 */

export const handleLoginSuccess = (
  res,
  loginObject,
  registrationScreen,
  redirectToCasinoPage,
  gameUrl
) => dispatch => {
  PubSub.emit(PubsubEvents.SESSION_CREATE, res.customer);
  if (handleLoginSuccessListener) {
    handleLoginSuccessListener?.unsubscribe();
  }
  handleLoginSuccessListener = PubSub.listen(
    PubsubEvents.SESSION_CREATED,
    () => {
      if (loginObject.fromBetslip === GLOBAL_CONSTANTS.FROM_BET_SLIP) {
        PubSub.emit(PubsubEvents.CallBetSlip, { place: true });
        dispatch(selectModal(false));
      } else if (
        loginObject.fromBetslip === GLOBAL_CONSTANTS.FROM_CASINO &&
        gameUrl
      ) {
        // gameUrl will be use to redirect the user on particular game page after login
        window.location.href = gameUrl;
      } else if (
        loginObject.fromBetslip === GLOBAL_CONSTANTS.FROM_CASINO ||
        window.location.href.indexOf('/casino/game/') > -1
      ) {
        PubSub.emit(PubsubEvents.callCasinoGame, { casino: true });
        dispatch(selectModal(false));
      } else {
        PubSub.emit(PubsubEvents.CallBetSlip, { place: false });
        dispatch(selectModal(false));
        if (registrationScreen) {
          PubSub.emit(PubsubEvents.SHOW_VERIFICATION_POPUP, false);
          PubSub.emit(PubsubEvents.SHOW_REGISTRATION_THIRD_STEP, true);
        } else if (
          loginObject.fromBetslip === GLOBAL_CONSTANTS.FROM_FORGOT ||
          window.location.pathname.match('forgot-password') ||
          window.location.pathname.match('register')
        ) {
          redirectToCasinoPage
            ? (window.location.href = '/casino/')
            : (window.location.href = '/sportsbook/');
        }
      }
      PubSub.emit(PubsubEvents.THIRD_PARTY_TRACKING, {
        event: TRACKING_CONSTANTS.LOGIN,
        data: { customerId: getCookie('custId') },
      });
      PubSub.emit(PubsubEvents.THIRD_PARTY_TRACKING, {
        event: TRACKING_CONSTANTS.LOGIN_SUCCESS,
        data: {
          customerId: res.customer.id,
          currencyCode: res.customer.currencyCode,
          balance: res.customer.balance,
        },
      });
    }
  );

  // Chechk made inside here to minimise unneccessary calls in core
  if (isAndroidApp()) {
    // Check for Adid to check android exists, if not don't call
    adjustTrackEvent(eventTokens.login, []);
  }

  addCustomGTMEventToDataLayer({
    event: gtmEventNames.LOGIN_SUCCESS,
    data: {
      user_id: res.customer.id,
      currencyCode: res.customer.currencyCode,
      balance: res.customer.balance,
    },
  });
};

/**
 * function handle HIO stuff
 * @param {object} cusRes
 * @param {object} loginObject
 * @param {bool} loginObject
 * @param {bool} redirectToCasinoPage
 */
const handeHIORequiredCondition = (
  cusRes,
  loginObject,
  registrationScreen,
  redirectToCasinoPage
) => dispatch => {
  const { customer } = cusRes;
  const customerUrl = '/fsb-api-rest/customer.fsb';
  const params = `?access_token=${customer.accessToken}&address=true&metadata=true`;
  return getRequest(`${customerUrl}${params}`).then(res => {
    const customerMetadata = res.customer.customerMetadata;
    if (
      !customerMetadata ||
      customerMetadata.filter(e => e.type === 'hioCondition').length === 0
    ) {
      // need to open detailpopup
      PubSub.emit(PubsubEvents.ADDITIONAL_DETAIL_REQUIRED, {
        cusRes: res,
        loginObject,
        registrationScreen,
        redirectToCasinoPage,
      });
      // close login popup
      PubSub.emit(PubsubEvents.REMOVE_LOGIN_MODEL);
      dispatch(selectModal(false));
      dispatch(changeUserName(''));
      dispatch(changePassWord(''));
      dispatch(changeMfaCode(''));
      dispatch(setErrorMessage(''));
      dispatch(setReturnCode(''));
    } else {
      // call successlogin suff
      dispatch(
        handleLoginSuccess(
          cusRes,
          loginObject,
          registrationScreen,
          redirectToCasinoPage
        )
      );
    }
  });
};

export const openPasswordModal = () => {
  PubSub.emit(PubsubEvents.openPasswordModal, { openModal: true });
};

const composeEnhancers = getComposeEnhancers({ name: 'login-store' });

export default () =>
  createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
