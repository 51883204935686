import React, { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  THW,
  LB,
  RB,
  RBW,
  RBM,
  EC,
  E,
  B,
  BS,
  R,
  RS,
  MB,
  BC,
  TCTP,
  CT,
  DP,
  SI as SI_LOCAL,
  UAI as UAI_LOCAL,
  IB as IB_LOCAL,
  BW,
  BETCOUNTER as BETCOUNTER_LOCAL,
  ACCOUNTBALANCECOUNTER as ACCOUNTBALANCECOUNTER_LOCAL,
  COL as COL_LOCAL,
  ROW as ROW_LOCAL,
  UBW as UBW_LOCAL,
} from 'UI/apps/AuthorizationHeader/AuthHeader';
import {
  SI as SI_GLOBAL,
  UAI as UAI_GLOBAL,
  MWN,
  MWD,
  IMAGE,
  BETCOUNTER as BETCOUNTER_GLOBAL,
  ACCOUNTBALANCECOUNTER as ACCOUNTBALANCECOUNTER_CORE,
  COL as COL_CORE,
  ROW as ROW_CORE,
  UBW as UBW_CORE,
} from 'CORE__UI/apps/AuthorizationHeader/core__authHeader';
import { CurrencyFormat } from 'Services/global/core__currency-format';
import I from 'UI/globals/Icons';
import { getIcon } from 'Services/icons/core__icons';
import { EYE } from '../../../core__appconfig.stub';
import { Translations } from '../../../core__authorization-header-app';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { setterGetterFromLocalstorage } from 'Services/localstorage/core__localstorage';
import accountRedirectUrls from 'Services/global/core__accountRedirectUrls';
import { StartCaptainUpService } from 'Services/core__services';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { getComponent } from 'Services/core__imports';
import { getUserBalance } from 'Services/global/core__manage-user-balance';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';

const SI = SI_LOCAL || SI_GLOBAL;
const UAI = UAI_LOCAL || UAI_GLOBAL;
const IB = IB_LOCAL ? IB_LOCAL : BW;
const BETCOUNTER = getComponent(BETCOUNTER_LOCAL, BETCOUNTER_GLOBAL);
const ACCOUNTBALANCECOUNTER = getComponent(
  ACCOUNTBALANCECOUNTER_LOCAL,
  ACCOUNTBALANCECOUNTER_CORE
);
const COL = getComponent(COL_LOCAL, COL_CORE);
const ROW = getComponent(ROW_LOCAL, ROW_CORE);
const UBW = getComponent(UBW_LOCAL, UBW_CORE);

/**
 * this component contains the header which includes login and sign up for the customer
 * @param {*} openLogin function for opening the login popup
 * @param {*} openRegistration function for opening the registration popup
 * @param {*} customerData object containing the details of the logged in customer
 * @param {*} openMyAccount function for opening the cusomer my account popup
 * @param {*} showBalance function for showing and hiding balance
 * @param {*} betCount number containing the bet count
 */

const renderAuth = (customerData, showBalance, show, showBet, betCount) => {
  const iconName = getIcon(show ? EYE.OPEN_EYE : EYE.CLOSED_EYE);
  return (
    <EC>
      <R>
        {showBet ? (
          <MB active={true}>{Translations.get('text.my.bets')}</MB>
        ) : (
          <MB active={false}>
            <a href="/my-account/">{Translations.get('text.my.bets')}</a>
          </MB>
        )}
        {showBet && <BC>{betCount || 0}</BC>}
      </R>

      <R>
        <RS id="rules" href="/rules/">
          {Translations.get('text.header.rules')}
        </RS>
        <RS id="help" href="/help/">
          {Translations.get('text.header.help')}
        </RS>
      </R>
      {customerData && (
        <BS>
          <E onClick={() => showBalance(show)}>
            {' '}
            <I iconName={iconName} />
          </E>
          <B>
            {customerData && CurrencyFormat(customerData?.currencyCode)}
            {!show
              ? EYE.HIDE
              : (customerData && customerData?.balance?.balance?.toFixed(2)) ||
                customerData?.balance?.toFixed(2) ||
                0}
          </B>
        </BS>
      )}
      {!customerData && (
        <TCTP>{Translations.get('text.header.insert-card')}</TCTP>
      )}
    </EC>
  );
};

export const AuthHeader = ({
  appConfig,
  openLogin,
  openRegistration,
  openMyAccount,
  orisisAuth,
  showBalance,
  theme,
  showLoggedInTimer,
  starIconIndicator,
}) => {
  const [displayStarIcon, setShowStarIcon] = useState(false);
  const showCreditLimit = appConfig?.showcreditlimit;
  const {
    customerData,
    show,
    showBet,
    betCount,
    customerBalance,
    customerCard,
  } = useSelector(state => ({
    customerData: state.data.customerData,
    show: state.data.show,
    showBet: state.data.showBet,
    betCount: state.data.betCount,
    customerBalance: state.data.customerBalance,
    customerCard: state.data.customerCard,
  }));
  const [showSecondaryColor, setShowSecondaryColor] = useState(false);
  const [userBalance, setUserBalance] = useState(0);
  const [userName, setUserName] = useState('');
  const [isCredit, setIsCredit] = useState(false);

  const {
    bplanBalance: {
      sportsbook: { balance: sportsbookBonus } = {},
      casino: { balance: casinoBonus } = {},
    } = {},
    wallet = {},
    unsettledBetsCount = 0,
  } = customerData || {};
  const disableDeposit = JSON.parse(appConfig?.disabledeposit || false);

  const [clockTimer, setLoginTimeDiff] = useState(null);
  const ResponsibleGambleShow = appConfig?.responsiblegambling;
  const IconUpdate = appConfig?.depositicon;
  const showVerifyPopup = appConfig?.kycflagcheck
    ? !customerData?.kycApproved
    : customerData?.raw?.accountStatus === GLOBAL_CONSTANTS.SUSPENDED &&
      !customerData?.kycApproved;

  const TurnOffDepositButton = appConfig?.disabledepositbutton;
  const olympic = appConfig?.olympic;
  const avatar = appConfig?.avatar;
  const showUsername = appConfig?.showusername;
  StartCaptainUpService();

  const withdrawableBalance = appConfig?.showwithdrawablebalance
    ? customerData?.balance?.withdrawableBalance
    : 0;

  const ButtonParent = olympic ? React.Fragment : RB;

  useEffect(() => {
    const element = document.getElementById('kycprompt');
    if (customerData && showVerifyPopup) {
      if (element) {
        element.style.display = 'flex';
        element?.classList?.add('kycpromptstyle');
        document.getElementById('header')?.classList?.add('loggedIn');
        PubSub.emit(PubsubEvents.KYC_PROMPT_ACTIVE, { showKycPrompt: true });
      }
    }
    if (
      appConfig?.requestURL === '/my-account/' &&
      appConfig.hidekycbanneronaccounts
    ) {
      if (element) {
        element.style.display = 'none';
        PubSub.emit(PubsubEvents.KYC_PROMPT_ACTIVE, { showKycPrompt: false });
      }
      document.getElementById('header')?.classList?.remove('loggedIn');
    }
  }, [customerData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      appConfig?.requestURL.includes(GLOBAL_CONSTANTS.S_CASINO) ||
      appConfig?.casinotheme
    ) {
      setShowSecondaryColor(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //caluclates user logged in time based on session created
  useEffect(() => {
    let interval;
    const sessionCreated =
      customerData?.sessionCreated || customerData?.raw?.sessionCreated;
    if (showLoggedInTimer && sessionCreated) {
      const then = new Date(sessionCreated);
      interval = setInterval(() => {
        const now = new Date();
        //calculates difference between logged in time and current time.
        let timeDiff = moment.utc(
          moment(now, 'DD/MM/YYYY HH:mm:ss').diff(
            moment(then, 'DD/MM/YYYY HH:mm:ss')
          )
        );
        const totalMinutes = timeDiff.hours();
        if (totalMinutes > 0) {
          timeDiff = timeDiff.format('HH:mm:ss');
        } else {
          timeDiff = timeDiff.format('mm:ss');
        }
        setLoginTimeDiff(timeDiff);
      }, 1000);
    }
    //clears interval on unmount
    return () => clearInterval(interval);
  }, [customerData, showLoggedInTimer]);

  useEffect(() => {
    const numOfFreeBets = Object.keys(wallet).length > 0;
    if (sportsbookBonus > 0 || casinoBonus > 0 || numOfFreeBets) {
      setShowStarIcon(true);
    }
    const Enablethirdstep = appConfig?.enablethirdstep;
    const findMetadata =
      (customerData?.raw?.customerMetadata?.length &&
        customerData?.raw?.customerMetadata?.find(
          data => data?.type === 'occupation'
        )) ||
      {};
    if (
      customerData?.raw?.accountStatus &&
      customerData?.raw?.accountStatus !== 'ACTIVE' &&
      Enablethirdstep &&
      !findMetadata?.id
    ) {
      if (
        customerData?.raw?.accountStatus === GLOBAL_CONSTANTS.SUSPENDED ||
        GLOBAL_CONSTANTS.PENDING_ACTIVATION
      ) {
        PubSub.emit(PubsubEvents.SHOW_REGISTRATION_THIRD_STEP, true);
        if (window.location.pathname !== '/register/') {
          window.location.href = '/register/';
        }
      }
    }
  }, [customerData]); // eslint-disable-line react-hooks/exhaustive-deps

  const sumOfWithDrawableBalance = (
    customerBalance,
    customerData,
    withdrawableBalance
  ) => {
    if (
      (customerBalance && !isNaN(customerBalance.balance)) ||
      (customerData?.balance?.balance &&
        !isNaN(customerData.balance.balance)) ||
      !isNaN(withdrawableBalance)
    ) {
      return (
        parseFloat(customerData.balance.balance) +
        parseFloat(withdrawableBalance)
      ).toFixed(2);
    }
  };

  useEffect(() => {
    customerData?.raw?.maxCredit &&
      setIsCredit(customerData?.raw?.creditBalance < 0);
    showCreditLimit
      ? setUserBalance(
          getUserBalance(customerBalance, customerData, showCreditLimit)
        )
      : setUserBalance(
          appConfig?.showwithdrawablebalance
            ? sumOfWithDrawableBalance(
                customerBalance,
                customerData,
                withdrawableBalance
              )
            : parseFloat(
                customerBalance?.balance?.toFixed(2) ||
                  customerBalance?.toFixed(2) ||
                  customerData?.balance?.balance?.toFixed(2) ||
                  customerData?.balance?.toFixed(2)
              )?.toFixed(2)
        );
    if (FSBCustomerSession?.customerOBJ)
      setUserName(
        FSBCustomerSession?.customerOBJ?.firstName +
          ' ' +
          FSBCustomerSession?.customerOBJ?.lastName
      );
  }, [customerBalance, customerData]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkUserSession = () => {
    const session = FSBCustomerSession.getSession();
    return session?.accessToken;
  };

  return (
    <Fragment>
      {!ResponsibleGambleShow
        ? !appConfig.standalone && (
            <THW isLoggedIn={customerData}>
              {!orisisAuth && (
                <Fragment>
                  {showLoggedInTimer && clockTimer && (
                    <CT>
                      <span>
                        <I
                          iconName={getIcon(GLOBAL_CONSTANTS.CLOCK)}
                          size={20}
                        />
                      </span>
                      {clockTimer}
                    </CT>
                  )}
                  {appConfig?.showunsettledbets ? (
                    <>
                      {customerData && (
                        <a href={accountRedirectUrls.myBets}>
                          <LB
                            isLoggedIn={customerData}
                            withBetCount={true}
                            showSecondaryColor={showSecondaryColor}
                          >
                            <Fragment>
                              <UAI>
                                <BETCOUNTER
                                  unsettledBetsCount={unsettledBetsCount}
                                >
                                  {unsettledBetsCount}
                                </BETCOUNTER>
                                <p>{Translations.get('text.my.bets')}</p>
                              </UAI>
                            </Fragment>
                          </LB>
                        </a>
                      )}
                      <LB
                        isLoggedIn={customerData}
                        showSecondaryColor={showSecondaryColor}
                        onClick={() => {
                          !customerData
                            ? openLogin()
                            : openMyAccount(customerData);
                        }}
                        aria-label="Header Login Button"
                      >
                        {!customerData ? (
                          Translations.get('text.login')
                        ) : (
                          <Fragment>
                            <UAI isLoggedIn={customerData}>
                              <ACCOUNTBALANCECOUNTER>
                                <I
                                  iconName={getIcon(GLOBAL_CONSTANTS.USER_ICON)}
                                  size={20}
                                />
                                {displayStarIcon && starIconIndicator && (
                                  <SI src={`${starIconIndicator}`} />
                                )}
                              </ACCOUNTBALANCECOUNTER>
                              <span>
                                {isCredit && showCreditLimit && '- '}
                                {CurrencyFormat(customerData?.currencyCode)}
                                {userBalance}
                              </span>
                            </UAI>
                          </Fragment>
                        )}
                      </LB>
                    </>
                  ) : (
                    <LB
                      isLoggedIn={checkUserSession()}
                      showSecondaryColor={showSecondaryColor}
                      onClick={() => {
                        !checkUserSession()
                          ? openLogin()
                          : openMyAccount(customerData);
                      }}
                      aria-label="Header Login Button"
                    >
                      {!checkUserSession() ? (
                        Translations.get('text.login')
                      ) : (
                        <Fragment>
                          <UAI>
                            <I
                              iconName={getIcon(GLOBAL_CONSTANTS.USER_ICON)}
                              size={20}
                            />
                            {displayStarIcon && starIconIndicator && (
                              <SI src={`${starIconIndicator}`} />
                            )}
                          </UAI>
                          {!showUsername ? (
                            <UBW>
                              {CurrencyFormat(customerData?.currencyCode)}
                              {userBalance}
                            </UBW>
                          ) : (
                            <COL uppercase alignItems={'center'}>
                              {userName}
                              <ROW>
                                {CurrencyFormat(customerData?.currencyCode)}
                                {userBalance}
                              </ROW>
                            </COL>
                          )}
                        </Fragment>
                      )}
                    </LB>
                  )}

                  {/* It will go inside one of the conditions if the app requires the user to verify its identify to deposit */}
                  {disableDeposit && (
                    <Fragment>
                      {customerData?.kycApproved &&
                      customerData?.accountStatus === 'ACTIVE' ? (
                        <RB theme={theme}>
                          {/* Logged in Customer balance */}
                          <a href={accountRedirectUrls.deposit}>
                            <RB hide={true}>
                              {Translations.get('text.deposit')}
                            </RB>
                          </a>
                        </RB>
                      ) : (
                        <Fragment>
                          {/* verify if the user is logged in */}
                          {customerData ? (
                            <RB theme={theme} disabled>
                              <Fragment>
                                <RB hide={true}>
                                  {Translations.get('text.deposit')}
                                </RB>
                              </Fragment>
                            </RB>
                          ) : (
                            <RB
                              showSecondaryColor={showSecondaryColor}
                              theme={theme}
                              onClick={openRegistration}
                            >
                              <RBW>{Translations.get('text.join.now')}</RBW>
                              <RBM>{Translations.get('text.join')}</RBM>
                            </RB>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                  {!disableDeposit && (
                    <ButtonParent
                      theme={theme}
                      hideOnMobile={appConfig.hidejoinmobile && customerData}
                    >
                      {/* verify if the user is logged in */}
                      {customerData ? (
                        // Logged in Customer balance
                        !TurnOffDepositButton &&
                        (appConfig.openquickdeposit &&
                        customerCard?.some(card => card.available) ? (
                          // button opens modal
                          <RB
                            onClick={() =>
                              PubSub.emit(PubsubEvents.OPE_QD, null)
                            }
                            hide={true}
                            showSecondaryColor={showSecondaryColor}
                          >
                            {Translations.get('text.deposit')}
                          </RB>
                        ) : (
                          // button redirects
                          // Logged in Customer balance
                          <a href={accountRedirectUrls.deposit}>
                            <RB
                              onClick={() =>
                                setterGetterFromLocalstorage({
                                  keyName: 'tabname',
                                  keyValue: 'deposit',
                                  action: 'set',
                                })
                              }
                              hide={true}
                              showSecondaryColor={showSecondaryColor}
                            >
                              {Translations.get('text.deposit')}
                            </RB>
                          </a>
                        ))
                      ) : (
                        <RB
                          showSecondaryColor={showSecondaryColor}
                          theme={theme}
                          onClick={openRegistration}
                        >
                          <RBW>{Translations.get('text.join.now')}</RBW>
                          <RBM>{Translations.get('text.join')}</RBM>
                        </RB>
                      )}
                    </ButtonParent>
                  )}
                </Fragment>
              )}
              {orisisAuth &&
                renderAuth(customerData, showBalance, show, showBet, betCount)}
            </THW>
          )
        : !appConfig.standalone && (
            <THW isLoggedIn={customerData}>
              {!orisisAuth && (
                <Fragment>
                  {showLoggedInTimer && clockTimer && (
                    <CT>
                      <span>
                        <I
                          iconName={getIcon(GLOBAL_CONSTANTS.CLOCK)}
                          size={20}
                        />
                      </span>
                      {clockTimer}
                    </CT>
                  )}
                  <DP>
                    {/* Logged in Customer balance */}
                    {customerData ? (
                      !TurnOffDepositButton && (
                        // Logged in Customer balance
                        <a href={accountRedirectUrls.deposit}>
                          <RB hide={true}>
                            {Translations.get('text.deposit')}
                          </RB>
                        </a>
                      )
                    ) : (
                      <RB theme={theme} onClick={openRegistration}>
                        <RBW>{Translations.get('text.register')}</RBW>
                        <RBM>{Translations.get('text.register')}</RBM>
                      </RB>
                    )}
                  </DP>
                  <LB
                    colorChange={!customerData && IconUpdate}
                    showSecondaryColor={showSecondaryColor}
                    onClick={() => {
                      !customerData ? openLogin() : openMyAccount(customerData);
                    }}
                    aria-label="Header Login Button"
                  >
                    {!customerData ? (
                      Translations.get('text.login')
                    ) : (
                      <React.Fragment>
                        {!olympic ? (
                          <IB>
                            {IconUpdate && <img src={IconUpdate}></img>}{' '}
                            {CurrencyFormat(customerData?.currencyCode)}{' '}
                            {parseFloat(userBalance)?.toFixed(2)}
                            {!IconUpdate && (
                              <span>
                                <I
                                  iconName={getIcon(GLOBAL_CONSTANTS.USER_ICON)}
                                  size={20}
                                />
                              </span>
                            )}
                          </IB>
                        ) : (
                          olympic && (
                            <React.Fragment>
                              {avatar && <img src={avatar}></img>}
                              <MWD>
                                <MWN>{customerData?.fullName}</MWN>
                                <IB>
                                  {parseFloat(userBalance)?.toFixed(2)}
                                  {CurrencyFormat(customerData?.currencyCode)}
                                  {!IconUpdate && (
                                    <span>
                                      <I
                                        iconName={getIcon(
                                          GLOBAL_CONSTANTS.USER_ICON
                                        )}
                                        size={20}
                                      />
                                    </span>
                                  )}
                                </IB>
                              </MWD>
                            </React.Fragment>
                          )
                        )}
                        {olympic && IconUpdate && (
                          <IMAGE src={IconUpdate}></IMAGE>
                        )}
                      </React.Fragment>
                    )}
                  </LB>
                </Fragment>
              )}
              {orisisAuth &&
                renderAuth(customerData, showBalance, show, showBet, betCount)}
            </THW>
          )}
    </Fragment>
  );
};

AuthHeader.propTypes = {
  responsiblegambling: PropTypes.string,
  appConfig: PropTypes.object,
  openLogin: PropTypes.func,
  openRegistration: PropTypes.func,
  openMyAccount: PropTypes.func,
  orisisAuth: PropTypes.bool,
  openLogout: PropTypes.func,
  showBalance: PropTypes.func,
  showJNAuth: PropTypes.bool,
  theme: PropTypes.string,
  showLoggedInTimer: PropTypes.bool,
  starIconIndicator: PropTypes.string,
};

export default AuthHeader;
