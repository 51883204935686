import styled from 'styled-components';
import { GREYS, FONT } from 'UI/globals/colours';
import ModalButton from 'UI/buttons/ModalButton';

export const MD = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ST = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
`;
export const T = styled.div`
  color: ${FONT.primary};
  text-align: center;
  font-size: 20px;
  font-weight: bold;
`;

export const I = styled.div`
  color: ${GREYS.black};
  padding: 4px;
  text-align: center;
`;
export const B = styled(ModalButton)`
  padding: 8px;
  margin-top: 8px;
  color: ${GREYS.white};
`;
export const MStyles = {
  modal: {
    padding: '24px',
    width: '480px',
    borderRadius: '4px',
    margin: 0,
  },
};
