import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'CORE__UI/globals/Modal/core__modal';
import { T, MD, I, ST, B, MStyles } from 'UI/globals/SessionExpiryStyles';
import { CloseIcon } from 'UI/globals/ModalCloseIcon';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { removeCookie } from 'Services/cookie/core__cookies';
import project from '../../../../project';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
/**
 *
 * @param {string} title
 * @param {string} info
 * @param {Array} noEventData
 * @returns a UI component for when there is no event available
 */

const SessionExpiry = () => {
  // when user session expires

  const [showModal, isShowModal] = useState(false);
  const sessionToken = FSBCustomerSession.getSession();
  const [session, setSession] = useState(null);
  useEffect(() => {
    setSession(sessionToken);
    const sessionExpiredListener = PubSub.listen(
      PubsubEvents.SESSION_EXPIRED,
      () => {
        isShowModal(true);
      }
    );
    const sessionCreatedListener = PubSub.listen('session:created', () => {
      const FSBSession = FSBCustomerSession.getSession();
      isShowModal(false);
      setSession(FSBSession);
    });
    const sessionUpdatedListener = PubSub.listenBehavior(
      'session:updated',
      () => {
        const FSBSession = FSBCustomerSession.getSession();
        isShowModal(false);
        setSession(FSBSession);
      }
    );

    return () => {
      sessionCreatedListener?.unsubscribe();
      sessionExpiredListener?.unsubscribe();
      sessionUpdatedListener?.unsubscribe();
    };
  }, [sessionToken]);
  //close modal removing customer session emitting pubsub
  const closeModal = () => {
    if (project.casinoModalClose) {
      window.location.href = '/casino/';
    } else {
      window.location.href = '/sportsbook/';
    }
    PubSub.emit(PubsubEvents.SESSION_DESTROY);
    FSBCustomerSession.deleteSession();
    removeCookie('AppSession');
    removeCookie('realityCheckAckTime');
  };
  return (
    <React.Fragment>
      {session && (
        <Modal
          data-test="close-modal"
          open={showModal}
          onClose={() => closeModal()}
          center
          styles={MStyles}
          closeIcon={<CloseIcon />}
        >
          <MD>
            <ST>
              <T>Session Expired</T>
            </ST>
            <I>Your session Expired</I>
            <I>Please login again to continue</I>

            <B
              label={`Ok`}
              data-test="ok-button"
              onClick={() => closeModal()}
            />
          </MD>
        </Modal>
      )}
    </React.Fragment>
  );
};
SessionExpiry.propTypes = {
  subTitle: PropTypes.string,
  info: PropTypes.string,
  noEventData: PropTypes.array,
  showJNStyle: PropTypes.bool,
};

export default SessionExpiry;
